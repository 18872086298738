import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../../components/UI/FormButton';
import { 
    renderFieldTwoColumns ,
    renderFieldOneColumn
} from '../../../components/UI/Fileds';

import { setBookMemberFilter } from '../../../actions/bookMember';

const BookMemberSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.bookMember);

    useEffect(() => {

        const elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});
        
        dispatch(
            initialize('bookMemberSearchForm', filter)
        );

    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(
            setBookMemberFilter({})
        );
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(
            setBookMemberFilter(values)
        );
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>

                {/* Campos del formulario */}
                <div className="row margin">
                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-book-member-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre" 
                    />
                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-book-member-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos" 
                    />                    
                </div>

                <div className="row margin">
                    <Field type="text" name="email" id="email" 
                        label={ t('screens-book-member-list-email') } 
                        component={ renderFieldOneColumn } 
                        icon="email" 
                        key="email" 
                    />
                </div>

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'bookMemberSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(BookMemberSearchForm);