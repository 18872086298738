import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field, change } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import ScreensUserSelector from '../../screens/User/Selector';
import ScreensUserNew from '../../screens/User/New';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendOrganizer } from '../../actions/organizer';
import { startLoading, endLoading, fetchDemarcacionList } from '../../actions/ui';
import { fetchOrganizerUserList, sendUser } from '../../actions/user';

import { showError, showConfirmation } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const ScreensOrganizerForm = ({ submitting, handleSubmit, dirty }) => {

    const { t } = useTranslation();

    const { selected } = useSelector(state => state.organizer);

    const { demarcacionList } = useSelector(state => state.ui);

    const [user, setUser] = useState((selected) ? selected.user : { id: '', label: t('selector-user-no-selected') });

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchDemarcacionList()).then((res) => {
            
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(initialize('organizerForm', {
                id: selected.id,
                nombre: selected.nombre,
                apellidos: selected.apellidos,
                demarcacion_id: selected.demarcacion_id,
                user_id: selected.user_id
            }));
        } else {
            dispatch(initialize('organizerForm', { 
                nombre: '', 
                apellidos: '', 
                demarcacion_id: '',
                user_id: ''
            }));
        }

        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch, selected]);

    const handleSend = (values) => {
        if (!values.user_id) {
            showError(t('message-title-error'), t('message-error-no-user-selected'));
        }

        dispatch(startLoading());

        dispatch(
            sendOrganizer({Organizadores: {
                ...values,
                nombre: stringTrim(values.nombre),
                apellidos: stringTrim(values.apellidos)
            }})
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchUser = () => {
        const elem = document.getElementById('modal-selector-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectUser = (user) => {
        const elem = document.getElementById('modal-selector-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setUser(user);
        dispatch(change('organizerForm', 'user_id', user.id));
    };

    const handleShowNewUser = () => {
        const elem = document.getElementById('modal-nuevo-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleNewUser = (values) => {
        const elem = document.getElementById('modal-nuevo-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        dispatch(startLoading());
        dispatch(
            sendUser({ Users: values })
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                setUser(res.message.user);
                dispatch(change('organizerForm', 'user_id', res.message.user.id));
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-organizer-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre"
                        maxLength="128"
                    />

                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-organizer-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos"
                        maxLength="128" 
                    />
                </div>
                <div className="row margin">
                    <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-organizer-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="location_searching" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('selector-area-no-selected') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                            ))
                        }
                    </Field>

                    <Field type="hidden" name="user_id" id="user_id"
                        value= { user.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{t('Dados de acesso')}</label>
                    <p>
                        {/*
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-usuario"
                            onClick={ handleSearchUser }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        */}
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-nuevo-usuario"
                            onClick={ handleShowNewUser }
                        >
                            <i className="material-icons">add</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ user.username || t('selector-user-no-created') }</span>
                    </p>
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>    
            <PageTitle title={ t('screens-organizer-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-usuario" className="modal">
                <div className="modal-content">
                    <ScreensUserSelector
                        onSelect={ handleSelectUser }
                        fetchUserList={ fetchOrganizerUserList }
                    />
                </div>
            </div>

            <div id="modal-nuevo-usuario" className="modal">
                <div className="modal-content">
                    <ScreensUserNew
                        onSelect={ handleNewUser }
                        role="ROLE_ORGANIZADOR"
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = i18n.t('validator-error-nombre');
    }

    if (!values.apellidos) {
        errors.apellidos = i18n.t('validator-error-apellidos');
    }

    if (!values.demarcacion_id) {
        errors.demarcacion_id = i18n.t('validator-error-demarcacion');
    }

    if (!values.user_id) {
        errors.user_id = i18n.t('validator-error-user');
    }

    return errors;
};

export default reduxForm({
    form: 'organizerForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensOrganizerForm);