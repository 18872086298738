import TYPES from '../actions/types';

const INITIAL_STATE = {
    checking: true, // comprobando una autenticacion previa.
    // user
};

export const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPES.AUTH_LOGIN:
            return { 
                ...state, 
                checking: false, 
                user: action.payload 
            };
        case TYPES.AUTH_LOGOUT:
            return { checking: false };
        case TYPES.AUTH_CHECKED:
            return { 
                ...state, 
                checking: false 
            };
        default:
            return state;
    }
};