import TYPES from './types';


//----------------------------------------
export const setLanguage = (language) => {
    return (dispatch) => {
        localStorage.setItem('language', language);

        dispatch({ type: TYPES.SET_LANGUAGE, payload: language });
    };
};