import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setLanguage } from '../actions/language';

import AppRouter from '../routes/AppRouter';

//-----------------
const App = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        const language = localStorage.getItem('language');
        dispatch(setLanguage(language));

    }, [dispatch]);


    return <AppRouter />;

};

export default App;
