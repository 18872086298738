import TYPES from '../actions/types';

const INITIAL_STATE = {
    list: [],
    paging: {}
};

export const courseStudentReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case TYPES.SET_COURSE_STUDENTS:
            const { count, current, page, pageCount, sort, direction } = action.payload.paging;
            return {
                ...state, 
                list: action.payload.list, 
                paging: { count, current, page, pageCount, sort, direction }
            };

        default:
            return state;
    }

};