import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import i18n from 'i18next';
import { useParams, Link } from "react-router-dom";
  
import './Login.css';

import { resetPassword } from '../../actions/auth';

import { showError, showSuccess } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    icon,
    id,
    key
}) => (

    <div className="row margin">
        <div className="input-field col s12">
            <i className="material-icons prefix pt-2">{ icon }</i>
            <input { ...input } key={key} id={ id } type={ type } />
            <label htmlFor={input.name} className="center-align">
                { label }
            </label>
            <small className="errorTxt6">
                <div id="crole-error" className="error">
                    {touched && error}
                </div>
            </small>
        </div>
    </div>

);

const validate = (values) => {
    const errors = {};

    if (!values.password) {
        errors.password = i18n.t('validator-error-password');
    }

    if (!values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-confirm-password');
    }

    if (values.password !== values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-password-confirm-password');
    }

    return errors;
}

const ScreensUserResetPassword = ({ history, submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { token } = useParams();

    const handleChangePassword = (values) => {
        const { password } = values;

        dispatch(
            resetPassword({ 
                password: stringTrim(password), 
                token: token
            })
        ).then((res) => {
            if (res.ok) {
                showSuccess(t('message-title-success'), t('message-password-changed'));
                history.replace('/login');
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const renderForm = () => {

        return (
            <>
                <form className="login-form" onSubmit={ handleSubmit(handleChangePassword) }>
                    <div className="row">
                        <div className="input-field col s12">
                            <h5 className="ml-4 valign-wrapper">
                                <img src="/assets/images/logo.png" alt="Instituto de Constelaciones Familiares Brigitte Champetier de Ribes" className="responsive-img" style={{maxWidth: "50px", "marginRight": "15px"}} />
                                { t('change_password_title') }
                            </h5>
                        </div>
                    </div>

                    <Field type="password" name="password" id="password" 
                        label={ t('input_password') } 
                        component={ renderField } 
                        icon="lock_outline" 
                        key="password"
                    />

                    <Field type="password" name="confirm_password" id="confirm_password" 
                        label={ t('input_confirm_password') } 
                        component={ renderField } 
                        icon="lock" 
                        key="comnfirm_password" 
                    />

                    <div className="row">
                        <div className="input-field col s12">
                            <button type="submit" className="btn waves-effect waves-light pink darken-4 col s12" disabled={ submitting }>
                                { t('form-button-send') }
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6 m6 l6">
                            <p className="margin medium-small">
                                <Link to="/login"
                                    title={ t('link_login_password') }
                                >
                                    { t('link_login_password') }
                                </Link>
                            </p>
                        </div>
                    </div>
                </form>
            </>
        );

    };

    return (
        <div className="row">
            <div className="col s12">
                <div className="container">
                    <div id="login-page" className="row">
                        <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                
                            { renderForm() }
                
                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'resetPasswordForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserResetPassword);