import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../helpers/messages';

import BookSearchForm from './SearchForm';
import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';
import FloatingButtonAction from '../../components/UI/FloatingButtonAction';

import { 
    fetchBooks, 
    downloadExcel, 
    selectBook, 
    deleteBook } from '../../actions/book';
import { 
    startLoading, 
    endLoading
} from '../../actions/ui';

const ScreensBookList = ({ autor, titulo, editorial, biblioteca_seccion_id }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.book);
    const { sort, direction } = paging;

    const { user } = useSelector(state => state.auth);

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchBooks(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    useEffect(() => {
        dispatch(selectBook(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleEdit = (item) => {
        dispatch(selectBook(item));
        history.push('/biblioteca/libros/formulario');
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-book-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteBook(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                autor: autor,
                titulo: titulo,
                editorial: editorial,
                biblioteca_seccion_id: biblioteca_seccion_id
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleAdd = () => {
        dispatch(selectBook(null));
        history.push('/biblioteca/libros/formulario');
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-book-list-autor') }
                            direction={ (sort === 'autor') ? direction : '' }
                            onClick={ () => handleSort('autor') }
                        />
                        <SortingHeader title={ t('screens-book-list-titulo') }
                            direction={ (sort === 'titulo') ? direction : '' }
                            onClick={ () => handleSort('titulo') }
                        />
                        <SortingHeader title={ t('screens-book-list-editorial') }
                            direction={ (sort === 'editorial') ? direction : '' }
                            onClick={ () => handleSort('editorial') }
                        />
                        <th>{ t('screens-book-list-seccion') }</th>
                        {
                            (user.alumnos.length === 0)
                            ? <th>{ t('screens-list-acciones') }</th>
                            : <></>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.autor }</td>
                                <td>{ item.titulo }</td>
                                <td>{ item.editorial }</td>
                                <td>{ item.biblioteca_seccion.nombre }</td>
                                {
                                    (user.alumnos.length === 0)
                                    ? <td>
                                    <Can perform="BibliotecaLibros:edit" yes={() => (
                                        <ActionButton icon="edit" 
                                        tooltip={ t('screens-list-acciones-edit') }
                                        extraClass="green darken-2" 
                                        onClick={ () => handleEdit(item) } />
                                    )} />
                                    <Can perform="BibliotecaLibros:delete" yes={() => (
                                         <ActionButton icon="delete_forever"
                                         tooltip={ t('screens-list-acciones-delete') } 
                                         extraClass="red ml-3" 
                                         onClick={ () => handleDelete(item.id, item.titulo) } />
                                    )} />
                                    </td>
                                    : <></>
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={ t('screens-book-list-title') }>
                <Can perform="BibliotecaLibros:excel" yes={() => (
                    <ExcelButton onPressButton={ handleExcel } />
                )} />
            </PageTitle>

            <BookSearchForm />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />

            <Can perform="BibliotecaLibros:add" yes={() => (
                <FloatingButtonAction icon="add" 
                    title={ t('screens-list-acciones-add') } action={ handleAdd }
                />
            )} />

        </PageContainer>
    );

};

const formSelector = formValueSelector('bookSearchForm');

export default connect(state => ({
    autor: formSelector(state, 'autor'),
    titulo: formSelector(state, 'titulo'),
    editorial: formSelector(state, 'editorial'),
    biblioteca_seccion_id: formSelector(state, 'biblioteca_seccion_id')
}))(ScreensBookList);