import React from 'react';

const PageContainer = ({ children }) => {
    return (
        <div className="col s12">
            <div className="container">
                <div className="section">
                    { children }
                </div>
            </div>
        </div>
    );
};

export default PageContainer;