import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchCourseStudents = (curso_id, filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                `/alumnos-cursos/inscritos/${curso_id}`, { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setCourseStudents(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setCourseStudents = (data) => ({
   type: TYPES.SET_COURSE_STUDENTS, payload: data
});

export const downloadCourseStudentsExcel = (curso) => {
    return async () => {
        try {
            const res = await downloadExcelFile(`/alumnos-cursos/excelInscritos/${curso.id}`, `inscritos-${curso.titulo.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '-')}.xlsx`, {}, {});
            const blob = await res.blob();
            fileDownload(blob, `inscritos-${curso.titulo.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '-')}.xlsx`);
            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};
