import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchCourseList = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/cursos/list', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, data: data }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log('error: ' + error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

export const fetchCourses = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/cursos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setCourses(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setCourses = (data) => ({
   type: TYPES.SET_COURSES, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile('/cursos/excel', 'cursos.xlsx', filter, {
                titulo: 'Cursos',
                cursos_tipo_id: 'Cursos',
                programados: 'Cursos'
            });
            const blob = await res.blob();
            fileDownload(blob, 'cursos.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectCourse = (data) => ({
    type: TYPES.SELECT_COURSE, payload: data
});

export const setCourseFilter = (filter) => ({
    type: TYPES.SET_COURSE_FILTER, payload: filter
});

export const deleteCourse = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/cursos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendCourse = (course) => {
    return async () => {
        try {
            let res;

            if (course.Cursos.id) {
                res = await apiClientWithToken().put(
                    `/cursos/${course.Cursos.id}`,
                    course
                );
            } else {
                res = await apiClientWithToken().post(
                    '/cursos', course
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendOnlineCourses = (cursos) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(cursos).forEach(([key, value]) => {
                if (value) {
                    formData.append(`Cursos[${key}]`, value);
                }
            });

            res = await apiClientWithToken().post(
                '/cursos/cargarCursosOnline', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'AuthToken': localStorage.getItem('token') || 'notoken'
                    }
                }
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message, errors: data.errores_excel};
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const downloadCertificates = (id) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/cursos/descargarCertificados/${ id }`, 
                `curso-${id}.pdf`
            );
            const blob = await res.blob();
            fileDownload(blob, `curso-${id}.pdf`);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const generateCertificates = (id) => {
    return async () => {
        try {
            const res = await apiClientWithToken().post(
                `/cursos/generarCertificados/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendFaceToFaceInscriptions = (course) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(course).forEach(([key, value]) => {
                if (value) {
                    formData.append(`Cursos[${key}]`, value);
                }
            });
            console.log("faceToFace");
            res = await apiClientWithToken().post(
                `/cursos/inscribirAlumnosPresencial/${course.id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'AuthToken': localStorage.getItem('token') || 'notoken'
                    }
                }
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message, alumnos_no_existentes: data.alumnos_no_existentes, usuarios_existentes: data.usuarios_existentes, errores_excel: data.errores_excel };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendVideoInscriptions = (course) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(course).forEach(([key, value]) => {
                if (value) {
                    formData.append(`Cursos[${key}]`, value);
                }
            });

            res = await apiClientWithToken().post(
                `/cursos/inscribirAlumnosVideo/${course.id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'AuthToken': localStorage.getItem('token') || 'notoken'
                    }
                }
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message, alumnos_no_existentes: data.alumnos_no_existentes, usuarios_existentes: data.usuarios_existentes, errores_excel: data.errores_excel };
            }

        } catch(error) {
            console.log('sendVideoInscriptions: ' + error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};
