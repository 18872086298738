import Swal from 'sweetalert2';

export const showError = (title, message) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'error'
    });
};

export const showErrorExcel = (title, message) => {    
    Swal.fire({
        title: title,
        html: message,
        icon: 'error'
    });
};

export const showSuccess = (title, message) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'success'
    });
};

export const showConfirmation = (title, message, okTitle, cancelTitle, onConfirm) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: okTitle,
        showCancelButton: true,
        cancelButtonText: cancelTitle
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
           onConfirm(); 
        }
    });
};