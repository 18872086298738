import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchDocumentList = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/documentos/list', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, data: data }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

export const fetchDocuments = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/documentos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setDocuments(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setDocuments = (data) => ({
   type: TYPES.SET_DOCUMENTS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/documentos/excel', 
                'documentos.xlsx',
                filter, {
                    nombre_original: 'Documentos',
                    descripcion: 'Documentos'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'documentos.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectDocument = (data) => ({
    type: TYPES.SELECT_DOCUMENT, payload: data
});

export const setDocumentFilter = (filter) => ({
    type: TYPES.SET_DOCUMENT_FILTER, payload: filter
});

export const deleteDocument = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/documentos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendDocument = (document) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(document).forEach(([key, value]) => {
                if (value) {
                    formData.append(`Documentos[${key}]`, value);
                }
            });

            if (document.id) {

                res = await apiClientWithToken().post(
                    `/documentos/edit/${document.id}`,
                    formData,
                    {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'AuthToken': localStorage.getItem('token') || 'notoken'
                        }
                    }
                );
            } else {
                res = await apiClientWithToken().post(
                    '/documentos', 
                    formData,
                    {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'AuthToken': localStorage.getItem('token') || 'notoken'
                        }
                    }
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const download = (document) => {
    return async () => {
        try {

            const res = await downloadFile(
                `/documentos/download/${ document.id }`, 
                document.nombre_original
            );
            const blob = await res.blob();
            fileDownload(blob, document.nombre_original);

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};