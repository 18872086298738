import React from 'react';

const ActionButton = ({ icon, extraClass, tooltip, onClick }) => {
    return (
        <button
            
            title={ tooltip }
            className={ `btn btn-small btn-floating waves-effect waves-light ${ extraClass }` }
            onClick={ onClick }
        >
            <i className="material-icons">{ icon }</i>
        </button>
    );
};

export default ActionButton;