import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../../components/UI/FormButton';
import { 
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../../components/UI/Fileds';

import { setStudentTaskFilter } from '../../../actions/studentTask';

import { fetchStatusTaskList } from '../../../actions/ui';

const TaskStudentSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.studentTask);

    const { statusTaskList } = useSelector(state => state.ui);

    useEffect(() => {

        const elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});

        dispatch(fetchStatusTaskList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });
        
        dispatch(
            initialize('studentTaskSearchForm', filter)
        );

    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(
            setStudentTaskFilter({})
        );
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(
            setStudentTaskFilter(values)
        );
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>

                <div className="row margin">
                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-student-task-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre" 
                    />
                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-student-task-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos" 
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-student-task-list-titulo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="titulo" 
                    />
                    <Field name="tareas_estado_id" id="tareas_estado_id" 
                        label={ t('screens-student-task-list-estado') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="flag" 
                        key="tareas_estado_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            statusTaskList.map(status => (
                                <option value={ status.id } key={ status.id }>{ status.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'studentTaskSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(TaskStudentSearchForm);