import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import Can from '../../../routes/Can';

import { showError } from '../../../helpers/messages';

import { dateToDatepicker } from '../../../helpers/converter';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Pagination from '../../../components/UI/Pagination';
import ExcelButton from '../../../components/UI/ExcelButton';
import Spacer from '../../../components/UI/Spacer';
import ActionButton from '../../../components/UI/ActionButton';
import SortingHeader from '../../../components/UI/SortingHeader';

import { 
    fetchStudentCourseDone, 
    downloadStudentCourseDoneExcel,
    toggleAprobado
} from '../../../actions/studentCourse';
import { startLoading, endLoading } from '../../../actions/ui';

const ScreensStudentCourseDone = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, horas } = useSelector(state => state.studentCourseDone);
    const { sort, direction } = paging;

    const { selected } = useSelector(state => state.student);

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentCourseDone(selected ? selected.id : 0, options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });
    }, [dispatch, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, sort: name, direction: newDirection });
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadStudentCourseDoneExcel(selected.id, {})
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleBack = () => {
        history.goBack();
    }

    const cambiarAprobado = (id) => {
        dispatch(startLoading());
        dispatch(
            toggleAprobado(id)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            } else {
                fetchData({ ...paging, page: 1 });
            }
        });
    }

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-student-course-done-tipo') }</th>
                        <SortingHeader title={ t('screens-student-course-done-titulo') }
                            direction={ (sort === 'titulo') ? direction : '' }
                            onClick={ () => handleSort('titulo') }
                        />
                        <SortingHeader title={ t('screens-course-list-num_unidad') }
                            direction={ (sort === 'num_unidad') ? direction : '' }
                            onClick={ () => handleSort('num_unidad') }
                        />
                        <SortingHeader title={ t('screens-student-course-done-fecha_inicio') }
                            direction={ (sort === 'fecha_inicio') ? direction : '' }
                            onClick={ () => handleSort('fecha_inicio') }
                        />
                        <SortingHeader title={ t('screens-student-course-done-fecha_fin') }
                            direction={ (sort === 'fecha_fin') ? direction : '' }
                            onClick={ () => handleSort('fecha_fin') }
                        />
                        <th>{ t('screens-student-course-done-horas') }</th>
                        <th>{ t('screens-student-course-done-evaluacion') }</th>
                        <th>{ t('screens-course-list-formador') }</th>
                        <th>{ t('screens-student-course-done-aprobado') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.curso.id }>
                                <td>{ item.curso.cursos_tipo.nombre }</td>
                                <td>{ item.curso.titulo }</td>
                                <td>{ item.curso.num_unidad }</td>
                                <td>{ dateToDatepicker(item.curso.fecha_inicio) }</td>
                                <td>{ dateToDatepicker(item.curso.fecha_fin) }</td>
                                <td>{ item.curso.horas }</td>
                                <td>{ item.evaluacion }</td>
                                { (item.curso.formadores_homologado !=null ) ?
                                   <td>{ item.curso.formadores_homologado.alumno.label }</td>  : <td>{ 'Sin definir' }</td>}
                                <td>
                                {
                                    (/*item.curso.cursos_tipo.nombre === 'Online' && */ item.aprobado)
                                    ? <span onClick={() => cambiarAprobado(item.id)} style={{cursor: 'pointer'}} className="badge green darken-2">{t('si')}</span> 
                                    : <span style={{cursor: 'pointer'}} onClick={() => cambiarAprobado(item.id)} className="badge red darken-2">{t('no')}</span> 
                                }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-student-course-done-title') }>
                <Can perform="AlumnosCursos:cursosRealizadosExcel" yes={() => (
                    <ExcelButton onPressButton={ handleExcel } />
                )} />
                <ActionButton icon="arrow_back"
                    tooltip={ t('screens-list-acciones-volver') } 
                    extraClass="grey text-black ml-3" 
                    onClick={ () => handleBack() } />
            </PageTitle>

            <blockquote className="small red-text">
                <label>{ selected ? `${selected.nombre} ${selected.apellidos}` : '' }</label><br />
                {
                    (horas)
                    ? <>
                        <label>{ (selected && horas.AlumnosHorasPresenciales) ? `${t('screens-course-students-list-horas_p')}: ${horas.AlumnosHorasPresenciales.horas_presenciales}` : ''}</label><br />
                        <label>{ (selected && horas.AlumnosHorasTalleres) ? `${t('screens-course-students-list-horas_t')}: ${horas.AlumnosHorasTalleres.horas_talleres}`: ''}</label><br />
                        <label>{ (selected && horas.AlumnosHorasOnline) ? `${t('screens-course-students-list-horas_o')}: ${horas.AlumnosHorasOnline.horas_online}`: ''}</label><br />
                        <label>{ (selected && horas.AlumnosHorasVideoconferencia) ? `${t('screens-course-students-list-horas_v')}: ${horas.AlumnosHorasVideoconferencia.horas_videoconferencia}`: ''}</label><br />
                        <label>{ (selected && horas.horas_totales) ? `${t('screens-course-students-list-horas_s')}: ${horas.horas_totales}`: ''}</label><br />
                    </>
                    : ''
                }
            </blockquote>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );
};

export default ScreensStudentCourseDone;