import React from 'react';

const Pagination = ({ paging, onPaginate }) => {

    const {
        count = 0, // registros totales.
        current = 0, // registros de la página actual.
        page = 1, // número de página actual
        pageCount = 1 //número de páginas
    } = paging;

    const MODULUS = 8;

    const handleFirst = () => {
        onPaginate(1);
    };

    const handlePrev = () => {
        onPaginate(page - 1);
    };

    const handleNext = () => {
        onPaginate(page + 1);
    };

    const handleLast = () => {
        onPaginate(pageCount);
    };

    const renderActionButton = (disabled, handler, icon) => {
        return (
            <button
                disabled={ disabled } 
                onClick={ handler }
                className="pagination btn btn-small btn-flat btn-floating"
            >
                <i className="material-icons">{ icon }</i>
            </button>
        );
    };

    const renderNumberButtons = () => {
        const buttonClass = 'btn btn-small btn-flat btn-floating';
        const items = [];

        if (pageCount > MODULUS) {

            let outOfRange = false;
            for(let index = 1; index <= pageCount; index++) {

                if (index <= 2 || index >= pageCount - 2 || Math.abs(index - page) <= 2) {

                    outOfRange = false;

                    items.push(
                        (page === index)
                            ? (
                                <li 
                                    key={ index }
                                    className={ `${buttonClass} active` }
                                    style={{width: '50px'}}
                                >
                                    <span>{ index }</span>
                                </li>
                            )
                            : (
                                <li 
                                    key={ index }
                                    onClick={ () => onPaginate(index) }
                                    className={ buttonClass }
                                    style={{width: '50px'}}
                                >
                                    <span>{ index }</span>
                                </li>
                            )
                    );
                
                } else {

                    if (!outOfRange) {
                        items.push(
                            <li key={ index }><span>...</span></li>
                        );
                    }

                    outOfRange = true;

                }
            } 

        } else {
            for (let index = 1; index <= pageCount; index++) {
                items.push(
                    (page === index)
                        ? (
                            <li 
                                key={ index }
                                className={ `${buttonClass} active` }
                                style={{width: '50px'}}
                            >
                                <span>{ index }</span>
                            </li>
                        )
                        : (
                            <li 
                                key={ index }
                                onClick={ () => onPaginate(index) }
                                className={ buttonClass }
                                style={{width: '50px'}}
                            >
                                <span>{ index }</span>
                            </li>
                        )
                );
            }
        }

        return items;
    };

    return (
        <div className="row paginator">
            <div className="col s12">
                <p className="right"><span>{ `${current}/${count}` }</span></p>
                <ul className="pagination left">
                    <li>
                        { renderActionButton(page === 1, handleFirst, 'first_page') }
                    </li>

                    <li>
                        { renderActionButton(page === 1, handlePrev, 'chevron_left') }
                    </li>

                    { renderNumberButtons() }

                    <li>
                        { renderActionButton(page === pageCount, handleNext, 'chevron_right') }
                    </li>

                    <li>
                        { renderActionButton(page === pageCount, handleLast, 'last_page') }
                    </li>
                </ul>
            </div>
        </div>
    );

} ;

export default Pagination;