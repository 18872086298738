import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import i18n from 'i18next';
import { apiClientWithToken } from '../../services/backend';

import PageContainer from '../../components/UI/PageContainer';
import PageTitle from '../../components/UI/PageTitle';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';

import { showError, showConfirmation } from '../../helpers/messages';

import {
    renderFieldOneColumn
} from '../../components/UI/Fileds';

import { changePassword, startLogout } from '../../actions/auth';
import { startLoading, endLoading } from '../../actions/ui';
import { stringTrim } from '../../helpers/converter';

const ScreensUserProfile = ({ submitting, handleSubmit, reset, dirty }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);

    const handleChangePassword = (values) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-change-password-text'),
            t('button-title-change-password'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    changePassword({
                        id: user.id,
                        password: stringTrim(values.password)
                    })
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        dispatch(startLogout());
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const renderUser = () => {
        return (
            <ul className="collection">
                {
                    user.alumnos.map((alumno, index) => {
                        return (
                            <li key={index} className="collection-item">
                                {alumno.label}
                                <i className="material-icons left">person</i>
                            </li>
                        )
                    })
                }
                <li key="-2" className="collection-item">
                    {user.username}<i className="material-icons left">person</i>
                </li>
                {
                    user.roles.map((role, index) => {
                        return (
                            <li key={index} className="collection-item">
                                {role.description}
                                <i className="material-icons left">folder_shared</i>
                            </li>
                        )
                    })
                }
                {
                    user.alumnos.map((alumno, index) => {
                        return (
                            <li key={index} className="collection-item">
                                {((alumno.poblacion === "") ? 'Población' : alumno.poblacion) + ' ' +
                                    ((alumno.codigo_postal === "") ? '( CP )' : alumno.codigo_postal) + ' - ' +
                                    ((alumno.provincia === "") ? 'Provincia' : alumno.provincia) + ' - ' +
                                    ((alumno.pais === "") ? 'País' : alumno.pais)
                                }
                                <i className="material-icons left">map</i>
                            </li>
                        )
                    })
                }
                {
                    user.alumnos.map((alumno, index) => {
                        return (
                            <li key={index} className="collection-item">
                                {((alumno.telefono_fijo === "") ? '-' : alumno.telefono_fijo)}
                                <i className="material-icons left">phone</i>
                            </li>
                        )
                    })
                }
                {
                    user.alumnos.map((alumno, index) => {
                        return (
                            <li key={index} className="collection-item">
                                {((alumno.telefono_movil === "") ? '-' : alumno.telefono_movil)}
                                <i className="material-icons left">tablet</i>
                            </li>
                        )
                    })
                }
                { 
                /*{
                    user.roles.map((role, index) => {
                        if (role.code == 'ROLE_ADMINISTRADOR') {
                            return (
                                <li className="collection-item" key={index}>
                                    <button className='btn btn-small waves-effect waves-light amber darken-4' onClick={handleDeleteDuplicatedFacilitadores}>
                                        Ejecutar Script Facilitadores Duplicados
                                        <i className="material-icons left mt-1">build</i>
                                    </button>
                                </li>
                            )
                        }
                    })
                {
                    user.roles.map((role, index) => {
                        if (role.code == 'ROLE_ADMINISTRADOR') {
                            return (
                                <li className="collection-item" key={index}>
                                    <button className='btn btn-small waves-effect waves-light amber darken-4' onClick={handleExecuteScriptFormadores}>
                                        Ejecutar Script Formadores
                                        <i className="material-icons left mt-1">build</i>
                                    </button>
                                </li>
                            )
                        }
                    })
                } */}
            </ul>
        );
    };

    const handleDeleteDuplicatedFacilitadores = async () => {
        try {
            const res = await apiClientWithToken().post('/users/deleteDuplicatedFacilitadores', {});

            const { ok, result: { data } } = res.data;

            if (ok) {
                console.log(res.data.result.data.message);
            }
        } catch (error) {
            console.error('Error al ejecutar el script:', error);
        }
    };
    
    // const handleExecuteScriptFormadores = async () => {
    //     try {
    //         const res = await apiClientWithToken().post('/users/executeScriptFormadores', {});

    //         const { ok, result: { data } } = res.data;

    //         if (ok) {
    //             console.log(res.data.result.data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error al ejecutar el script:', error);
    //     }
    // };
    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit(handleChangePassword)} id="change-password-form">
                <h5>{t('screens-user-profile-change-password')}</h5>

                <div className="row">
                    <Field type="password" name="password" id="password"
                        label={t('screens-profile-password')}
                        placeholder=""
                        component={renderFieldOneColumn}
                        icon="lock"
                        key="password"
                    />
                </div>

                <div className="row">
                    <Field type="password" name="confirm_password" id="confirm_password"
                        label={t('screens-profile-confirm-password')}
                        placeholder=""
                        component={renderFieldOneColumn}
                        icon="lock_outline"
                        key="confirm_password"
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={t('form-button-send')}
                            disabled={submitting}
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={t('screens-user-profile-title')} />

            <ContentContainer>

                <div className="row">
                    <div className="col s12 m6">
                        {renderUser()}
                    </div>
                    <div className="col s12 m6">
                        {renderForm()}
                    </div>
                </div>

            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );


};

const validate = (values) => {
    const errors = {};

    if (!values.password) {
        errors.password = i18n.t('validator-error-password');
    }

    if (!values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-confirm-password');
    }

    if (values.password !== values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-password-confirm-password');
    }

    return errors;
};

export default reduxForm({
    form: 'profileForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserProfile);