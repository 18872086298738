import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../components/UI/PageContainer';
import PageTitle from '../../components/UI/PageTitle';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import FormButton from '../../components/UI/FormButton';

import { showError } from '../../helpers/messages';

import { 
    renderFieldTwoColumns
} from '../../components/UI/Fileds';

const ScreensDocumentSelector = ({ onSelect, submitting, handleSubmit, reset, fetchDocumentList }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [{ paging, list }, setSearch] = useState({ paging: {}, list: [] });

    const handleFetch = (resp) =>  {
        if (resp.ok) {
            const {count, current, page, pageCount } = resp.data.paging;
            setSearch({
                paging: { count, current, page, pageCount },
                list: resp.data.list
            });
        } else {
            showError(t('message-title-error'), t(resp.message));
        }
    };

    const handlePaginate = (index) => {
        const values = {
            nombre_original: document.getElementById("nombre_original").value,
            descripcion: document.getElementById("descripcion").value,
        };
        dispatch(
            fetchDocumentList({ ...paging, ...values, page: index })
        ).then(handleFetch);
    };

    const handleSearch = (values) => {
        dispatch(
            fetchDocumentList({ ...paging, ...values, page: 1 })
        ).then(handleFetch);
    }

    const handleReset = () => {
        dispatch(reset('documentSelectorForm'));
    };

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="nombre_original" id="nombre_original" 
                        label={ t('screens-document-list-archivo') } 
                        component={ renderFieldTwoColumns } 
                        icon="attach_file" 
                        key="nombre_original" 
                    />
                    <Field type="text" name="descripcion" id="descripcion" 
                        label={ t('screens-document-list-descripcion') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="descripcion" 
                    />                    
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>
            </form>
        );
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-list-acciones') }</th>
                        <th>{ t('screens-document-selector-archivo') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>
                                    <ActionButton icon="arrow_forward"
                                        tooltip={ t('screens-list-acciones-select') } 
                                        extraClass="pink darken-4 ml-3" 
                                        onClick={ () => onSelect(item) } />
                                </td>
                                <td>{ item.nombre_original }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-document-selector-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'documentSelectorForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensDocumentSelector);