import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError } from '../../helpers/messages';

import { dateToDatepicker } from '../../helpers/converter';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';

import { 
    fetchCourseStudents, 
    downloadCourseStudentsExcel, 
} from '../../actions/courseStudent';
import { startLoading, endLoading } from '../../actions/ui';

const ScreensCourseStudentsList = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging } = useSelector(state => state.courseStudent);
    const { sort, direction } = paging;

    const { selected } = useSelector(state => state.course);

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchCourseStudents(selected ? selected.id : 0, options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });
    }, [dispatch, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, sort: name, direction: newDirection });
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadCourseStudentsExcel(selected)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleBack = () => {
        history.goBack();
    }

    const getTelefono = (alumno) => {
        const parts = [];
        if (alumno.telefono_movil) {
            parts.push(alumno.telefono_movil);
        }
        if (alumno.telefono_fijo) {
            parts.push(alumno.telefono_fijo);
        }
        return parts.join(' - ');
    }

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-course-students-list-apellidos') }
                            direction={ (sort === 'apellidos') ? direction : '' }
                            onClick={ () => handleSort('apellidos') }
                        />
                        <SortingHeader title={ t('screens-course-students-list-nombre') }
                            direction={ (sort === 'nombre') ? direction : '' }
                            onClick={ () => handleSort('nombre') }
                        />
                        <SortingHeader title={ t('screens-course-students-list-comienzo') }
                            direction={ (sort === 'comienzo') ? direction : '' }
                            onClick={ () => handleSort('comienzo') }
                        />
                        <th>{ t('screens-course-students-list-email') }</th>
                        <th>{ t('screens-course-students-list-telefono') }</th>
                        <th>{ t('screens-course-students-list-fpa') }</th>
                        <th>{ t('screens-course-students-list-horas_p') }</th>
                        <th>{ t('screens-course-students-list-horas_t') }</th>
                        <th>{ t('screens-course-students-list-horas_o') }</th>
                        <th>{ t('screens-course-students-list-horas_v') }</th>
                        <th>{ t('screens-course-students-list-horas_s') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.alumno.email }>
                                <td>{ item.alumno.apellidos }</td>
                                <td>{ item.alumno.nombre }</td>
                                <td>{ dateToDatepicker(item.alumno.comienzo) }</td>
                                <td>{ item.alumno.email }</td>
                                <td>{ getTelefono(item.alumno) }</td>
                                <td>{ (item.alumno.formacion_previa_acreditada) ? t('si') : t('no') }</td>
                                <td>{ item.AlumnosHorasPresenciales.horas_presenciales || 0 }</td>
                                <td>{ item.AlumnosHorasTalleres.horas_talleres || 0 }</td>
                                <td>{ item.AlumnosHorasOnline.horas_online || 0 }</td>
                                <td>{ item.AlumnosHorasVideoconferencia.horas_videoconferencia || 0 }</td>
                                <td>{ item.horas_totales }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    const lugar = [];
    if (selected && selected.lugar) {
        lugar.push(selected.lugar);
    }
    if (selected && selected.ciudad) {
        lugar.push(selected.ciudad);
    }
    if (selected && selected.demarcacion) {
        lugar.push(selected.demarcacion.nombre);
    }

    return (
        <PageContainer>

            <PageTitle title={ t('screens-course-students-list-title') }>
                <Can perform="AlumnosCursos:excel" yes={() => (
                    <ExcelButton onPressButton={ handleExcel } />
                )} />
                <ActionButton icon="arrow_back"
                    tooltip={ t('screens-list-acciones-volver') } 
                    extraClass="grey text-black ml-3" 
                    onClick={ () => handleBack() } />
            </PageTitle>

            <blockquote className="small red-text">
                <label>{selected ? `${selected.titulo} - ${selected.cursos_tipo.nombre}` : ''}</label>
                {
                    (selected && selected.fecha_inicio)
                    ? <><br /><label>{`${dateToDatepicker(selected.fecha_inicio)}`}</label></>
                    : ''
                }
                {
                    (lugar.length > 0)
                    ? <><br /><label>{`${lugar.join(' - ')}`}</label></>
                    : ''
                }
            </blockquote>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />

        </PageContainer>
    );

};

export default ScreensCourseStudentsList;