import axios from 'axios';
//import fileDownload from 'js-file-download';

import { startLogout } from '../actions/auth'; // tratamiento 401.
import { store } from '../store/store'; // tratamiento 401.
//Modificacion43443
// const BASE_URL = 'http://localhost/insconsfaApi/api'; // Local
// const BASE_URL = 'https://api.insconsfa-pruebas.com/api'; //Test
// const BASE_URL = 'http://local.insconsfapapi.com/api';
const BASE_URL = 'https://api.insconsfa.com/api'; // Producción

export const apiClientWithToken = () => {
    return axios.create({
        baseURL: BASE_URL,
        timeout: 35000,
        headers: {
            'Content-Type': 'application/json', //'application/x-www-form-urlencoded',
            'AuthToken': localStorage.getItem('token') || 'notoken',
            'language': localStorage.getItem('language') || 'es'
        },
        validateStatus: function (status) {
            if (status === 401 || status === 403) {
                store.dispatch(startLogout());
            }
            return (status >= 200 && status <= 400) || status === 409;
        }
    });
};

export const apiClientWithoutToken = () => {
    return axios.create({
        baseURL: BASE_URL,
        timeout: 5000,
        headers: {
            'Content-Type': 'application/json', //'application/x-www-form-urlencoded'
            'AuthToken': 'notoken',
            'language': localStorage.getItem('language') || 'es'
        },
        validateStatus: function (status) {
            return (status >= 200 && status <= 400) || status === 409;
        },
    });
};

export const downloadExcelFile = async (url, filename, filter, entities) => {
    const data = new FormData();
    Object.entries(filter).forEach(([key, value]) => {
        if (value) {
            data.append(`${entities[key]}[${key}]`, value);
        }
    });

    // fetch(`${BASE_URL}${url}`, {
    //     method: 'POST',
    //     body: data,
    //     headers: {
    //         //'Content-Type': 'application/x-www-form-urlencoded',
    //         'AuthToken': localStorage.getItem('token') || 'notoken',
    //         'language': localStorage.getItem('language') || 'es'
    //     }
    // }).then(res => res.blob().then(blob => {
    //     fileDownload(blob, filename);
    // }));
    return fetch(`${BASE_URL}${url}`, {
        method: 'POST',
        body: data,
        headers: {
            //'Content-Type': 'application/x-www-form-urlencoded',
            'AuthToken': localStorage.getItem('token') || 'notoken',
            'language': localStorage.getItem('language') || 'es'
        }
    });
};

export const downloadFile = (url, filename) => {
    return fetch(`${BASE_URL}${url}`, {
        method: 'GET',
        headers: {
            'AuthToken': localStorage.getItem('token') || 'notoken',
            'language': localStorage.getItem('language') || 'es'
        }
    });
    // .then(res => res.blob().then(blob => {
    //     fileDownload(blob, filename);
    // }));
};