import React from 'react';

//------------------------------
const Waiting = ({ title }) => {

    return (
        <div className="col s-2 offset-s5 center-align">
            <div className="preloader-wrapper big active">
                <div className="spinner-layer spinner-blue-only">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>            
            </div>
            <h4 className="center-align">{ title }</h4>
        </div>
    );

};

export default Waiting;