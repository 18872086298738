import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchBookSections = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/biblioteca-secciones', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setBookSections(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setBookSections = (data) => ({
   type: TYPES.SET_BOOK_SECTIONS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/biblioteca-secciones/excel', 
                'secciones-biblioteca.xlsx',
                filter, {
                    nombre: 'BibliotecaSecciones',
                    codigo: 'BibliotecaSecciones'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'secciones-biblioteca.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectBookSection = (data) => ({
    type: TYPES.SELECT_BOOK_SECTION, payload: data
});

export const setBookSectionFilter = (filter) => ({
    type: TYPES.SET_BOOK_SECTION_FILTER, payload: filter
});

export const deleteBookSection = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/biblioteca-secciones/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendBookSection = (section) => {
    return async () => {
        try {
            let res;

            if (section.BibliotecaSecciones.id) {
                res = await apiClientWithToken().put(
                    `/biblioteca-secciones/${section.BibliotecaSecciones.id}`,
                    section
                );
            } else {
                res = await apiClientWithToken().post(
                    '/biblioteca-secciones', section
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};