import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field, change } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import ScreensStudentSelector from '../Student/Selector';

import {
    renderFieldOneColumn,
    renderSelectFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendTrainer } from '../../actions/trainer';
import { startLoading, endLoading, fetchDemarcacionList } from '../../actions/ui';
import { fetchStudentListNoFormadores } from '../../actions/student';

import { showError, showConfirmation } from '../../helpers/messages';

const ScreensTrainerForm = ({ submitting, handleSubmit, dirty }) => {

    const { t } = useTranslation();

    const { selected } = useSelector(state => state.trainer);

    const { demarcacionList } = useSelector(state => state.ui);

    const [student, setStudent] = useState(
        (selected)
            ? {
                ...selected.alumno,
                label: `${selected.alumno.apellidos}, ${selected.alumno.nombre} (${selected.alumno.email})`
            }
            : { id: '', label: t('selector-student-no-selected') }
    );

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchDemarcacionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            const tipoFacilitadorValue = selected.tipo === 'Facilitador' ? 'facilitador' : selected.tipo === 'Formador Homologado' ? 'formador' : '';
            dispatch(initialize('trainerForm', {
                id: selected.id,
                demarcacion_id: selected.demarcacion_id,
                tipoFacilitador: tipoFacilitadorValue
            }));
        } else {
            dispatch(initialize('trainerForm', {
                demarcacion_id: '',
                alumno_id: '',
                tipoFacilitador: ''
            }));
        }

        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch, selected]);

    const handleSend = (values) => {
        if (!selected && !values.alumno_id) {
            showError(t('message-title-error'), t('message-error-no-student-selected'));
        }

        dispatch(startLoading());
        dispatch(
            sendTrainer({ FormadoresHomologados: values })
        ).then((res) => {
            dispatch(endLoading());
            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                }
            );
        } else {
            history.goBack();
        }
    };

    const handleSearchStudent = () => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectStudent = (student) => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setStudent(student);
        dispatch(change('trainerForm', 'alumno_id', student.id));
    };

    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit(handleSend)}>
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden"
                                    name="id"
                                    id="id"
                                    component={renderFieldOneColumn}
                                />
                            )
                            : ''
                    }

                    <Field name="demarcacion_id" id="demarcacion_id"
                        label={t('screens-organizer-list-demarcacion')}
                        component={renderSelectFieldTwoColumns}
                        icon="location_searching"
                        key="demarcacion_id"
                    >
                        <option value="">{t('selector-area-no-selected')}</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={demarcacion.id} key={demarcacion.id}>{demarcacion.nombre}</option>
                            ))
                        }
                    </Field>

                    <Field name="tipoFacilitador" id="tipoFacilitador"
                        label={t('screens-trainer-list-tipo')}
                        component={renderSelectFieldTwoColumns}
                        icon="format_list_bulleted"
                        key="tipoFacilitador"
                    >
                        <option value="">-- Seleccione el tipo de facilitador --</option>
                        <option value="facilitador">Facilitador</option>
                        <option value="formador">Formador Homologado</option>
                    </Field>

                    {
                        (selected)
                            ? <>
                                <label>{t('screens-student-selector-alumno')}</label>
                                <p>{`${selected.alumno.apellidos}, ${selected.alumno.nombre} (${selected.alumno.email})`}</p>
                            </>
                            : <>
                                <Field type="hidden" name="alumno_id" id="alumno_id"
                                    value={student.id}
                                    component={renderFieldOneColumn}
                                />
                                <label className="ml-1">{t('screens-student-selector-alumno')}</label>
                                <p>
                                    <button
                                        className="btn modal-trigger ml-1"
                                        data-target="modal-selector-alumno"
                                        onClick={handleSearchStudent}
                                    >
                                        <i className="material-icons">search</i>
                                    </button>
                                    <span style={{ marginLeft: '1em' }}>{student.label}</span>
                                </p>
                            </>
                    }
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={t('form-button-send')}
                            disabled={submitting}
                        />

                        <FormButton type="reset"
                            title={t('form-button-cancel')}
                            disabled={submitting}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={t('screens-trainer-form-title')} />

            <ContentContainer>
                {renderForm()}
            </ContentContainer>

            <div id="modal-selector-alumno" className="modal">
                <div className="modal-content">
                    <ScreensStudentSelector
                        onSelect={handleSelectStudent}
                        fetchStudentList={fetchStudentListNoFormadores}
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.demarcacion_id) {
        errors.demarcacion_id = i18n.t('validator-error-demarcacion');
    }

    if (values.tipoFacilitador == '') {
        errors.tipoFacilitador = i18n.t('validator-error-tipo');
    }

    return errors;
};

export default reduxForm({
    form: 'trainerForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensTrainerForm);