import React from 'react';

const FormButton = ({ type, title, disabled, onClick }) => {
    switch (type) {
        case 'submit':
            return (
                <button type="submit" className="btn btn-small waves-effect waves-light amber darken-4 right ml-2" disabled={ disabled }>
                    { title } <i className="material-icons right">send</i>
                </button>
            );

        case 'reset':
            return (
                <button type="reset" className="btn btn-small waves-effect waves-light right white black-text ml-2" disabled={ disabled } onClick={ onClick }>
                    { title } <i className="material-icons right">clear</i>
                </button>
            );

        default:
            return (<></>);
    }
};

export default FormButton;