import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../../helpers/messages';

import DegreeStudentSearchForm from './SearchForm';
import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Pagination from '../../../components/UI/Pagination';
import ExcelButton from '../../../components/UI/ExcelButton';
import Spacer from '../../../components/UI/Spacer';
import ActionButton from '../../../components/UI/ActionButton';
import SortingHeader from '../../../components/UI/SortingHeader';

import { 
    fetchStudentDegrees, 
    downloadExcel,
    selectStudentDegree,
    deleteStudentDegree } from '../../../actions/studentDegree';
import { startLoading, endLoading } from '../../../actions/ui';
import { dateToDatepicker } from '../../../helpers/converter';

const ScreensDegreeStudentList = ({ nombre, apellidos, poblacion, demarcacion_id, fecha_1, fecha_2, diplomas_tipo_id }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.studentDegree);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentDegrees(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-student-degree-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteStudentDegree(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                nombre: nombre,
                apellidos: apellidos,
                poblacion: poblacion,
                demarcacion_id: demarcacion_id,
                diplomas_tipo_id: diplomas_tipo_id,
                fecha_1: fecha_1,
                fecha_2: fecha_2
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleEdit = (item) => {
        dispatch(selectStudentDegree(item));
        history.push('/alumnos/titulaciones/formulario');    
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-student-degree-list-apellidos') }
                            direction={ (sort === 'apellidos') ? direction : '' }
                            onClick={ () => handleSort('apellidos') }
                        />
                        <SortingHeader title={ t('screens-student-degree-list-nombre') }
                            direction={ (sort === 'nombre') ? direction : '' }
                            onClick={ () => handleSort('nombre') }
                        />
                        <th>{ t('screens-student-degree-list-tipo') }</th>
                        <th>{ t('screens-student-degree-list-poblacion') }</th>
                        <SortingHeader title={ t('screens-student-degree-list-fecha_1') }
                            direction={ (sort === 'fecha_1') ? direction : '' }
                            onClick={ () => handleSort('fecha_1') }
                        />
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.alumno.apellidos }</td>
                                <td>{ item.alumno.nombre }</td>
                                <td>{ item.diplomas_tipo.nombre }</td>
                                <td>{ item.alumno.poblacion }</td>
                                <td>{ dateToDatepicker(item.fecha_1) }</td>
                                <td>
                                    <Can perform="AlumnosTitulaciones:edit" yes={() => (
                                        <ActionButton icon="edit" 
                                        tooltip={ t('screens-list-acciones-edit') }
                                        extraClass="green darken-2" 
                                        onClick={ () => handleEdit(item) } />
                                    )} />
                                    <Can perform="AlumnosTitulaciones:delete" yes={() => (
                                        <ActionButton icon="delete_forever"
                                        tooltip={ t('screens-list-acciones-delete') } 
                                        extraClass="red ml-3" 
                                        onClick={ () => handleDelete(item.id, `${item.diplomas_tipo.nombre} - ${item.alumno.nombre} ${item.alumno.apellidos}`) } />
                                    )} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={ t('screens-student-degree-list-title') }>
                <Can perform="AlumnosTitulaciones:excel" yes={() => (
                    <ExcelButton onPressButton={ handleExcel } />
                )} />
            </PageTitle>

            <DegreeStudentSearchForm />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );

};

const formSelector = formValueSelector('studentDegreeSearchForm');

export default connect(state => ({
    nombre: formSelector(state, 'nombre'),
    apellidos: formSelector(state, 'apellidos'),
    poblacion: formSelector(state, 'poblacion'),
    demarcacion_id: formSelector(state, 'demarcacion_id'),
    diplomas_tipo_id: formSelector(state, 'diplomas_tipo_id'),
    fecha_1: formSelector(state, 'fecha_1'),
    fecha_2: formSelector(state, 'fecha_2')
}))(ScreensDegreeStudentList);