import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import M from 'materialize-css';

import Can from '../../routes/Can';

import { MENU_DEFINITION } from './MenuBar';

const MenuBarMobile = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});
    }, []);

    const renderSubMenu = (id, submenu) => {
        return (
            <ul className="dropdown-content" id={`m-${id}`} data-collapsible="accordion">
                {submenu.map((item, index) => {
                    return (
                        <Can perform={item.perform} key={`can-${index}`}
                            yes={() => (
                                <li key={index}>
                                    <NavLink exact to={item.action}>
                                        <span data-i18n="Modern">
                                            { t(item.title) }
                                        </span>
                                    </NavLink>
                                </li>
                            )}
                        />
                    )
                })}
            </ul>
        );
    }

    return (
        <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-fixed hide-on-large-only">
            <div className="brand-sidebar sidenav-light"></div>
            <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed hide-on-large-only menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">

                {MENU_DEFINITION.map((menu, index) => {
                    return (
                        <Can perform={menu.perform} key={`can-${index}`}
                            yes={() => (
                                <li key={index}>
                                    <a className="dropdown-menu" href="#!" data-target={`m-${menu.id}`}>
                                        <i className="material-icons">{menu.icon}</i>
                                        <span>
                                            <span className="dropdown-title" data-i18n="Dashboard">
                                                { t(menu.title) }
                                            </span>
                                            <i className="material-icons right">keyboard_arrow_down</i>
                                        </span>
                                    </a>
                                    { renderSubMenu(menu.id, menu.options) }
                                </li>
                            )}
                        />
                    )
                })}
            </ul>
            {/*<div className="navigation-background"></div>*/}
            <a className="sidenav-trigger btn-floating btn-medium waves-effect waves-light hide-on-large-only" href="#!" data-target="slide-out">
                <i className="material-icons">menu</i>
            </a>
        </aside>
    );

    /*return (
        <>
            <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-fixed hide-on-large-only">
                <div className="brand-sidebar sidenav-light"></div>


                <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed hide-on-large-only menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
        <li className="active bold"><a className="collapsible-header waves-effect waves-cyan " href="#!"><i className="material-icons">settings_input_svideo</i><span className="menu-title" data-i18n="Dashboard">Dashboard</span><span className="badge badge pill red float-right mr-10">3</span></a>
          <div className="collapsible-body">
            <ul className="collapsible collapsible-sub" data-collapsible="accordion">
              <li className="active"><a className="active" href="dashboard-modern.html"><i className="material-icons">radio_button_unchecked</i><span data-i18n="Modern">Modern</span></a>
              </li>
              <li><a href="dashboard-ecommerce.html"><i className="material-icons">radio_button_unchecked</i><span data-i18n="eCommerce">eCommerce</span></a>
              </li>
              <li><a href="dashboard-analytics.html"><i className="material-icons">radio_button_unchecked</i><span data-i18n="Analytics">Analytics</span></a>
              </li>
            </ul>
          </div>
        </li>
        
      </ul>





                <div className="navigation-background"></div>
                    <a className="sidenav-trigger btn-floating btn-medium waves-effect waves-light hide-on-large-only" href="#!" data-target="slide-out">
                        <i className="material-icons">menu</i>
                    </a>
            </aside>
        </>
    );*/
};

export default MenuBarMobile;