import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../components/UI/PageContainer';
import PageTitle from '../../components/UI/PageTitle';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import FormButton from '../../components/UI/FormButton';

import { showError } from '../../helpers/messages';

import { 
    renderFieldTwoColumns,
    renderFieldOneColumn,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

const ScreensUserSelector = ({ onSelect, submitting, handleSubmit, reset, fetchUserList }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [{ paging, list }, setSearch] = useState({ paging: {}, list: [] });

    const handleFetch = (resp) =>  {
        if (resp.ok) {
            const {count, current, page, pageCount } = resp.data.paging;
            setSearch({
                paging: { count, current, page, pageCount },
                list: resp.data.list
            });
        } else {
            showError(t('message-title-error'), t(resp.message));
        }
    };

    const handlePaginate = (index) => {
        const values = {
            username: document.getElementById("username").value,
            active: document.getElementById("active").value,
            email: document.getElementById("email").value
        };
        dispatch(
            fetchUserList({ ...paging, ...values, page: index })
        ).then(handleFetch);
    };

    const handleSearch = (values) => {
        dispatch(
            fetchUserList({ ...paging, ...values, page: 1 })
        ).then(handleFetch);
    }

    const handleReset = () => {
        dispatch(reset('userSelectorForm'));
    };

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="username" id="username-selector" 
                        label={ t('screens-user-list-username') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="username-selector" 
                    />

                    <Field type="checkbox" name="active" id="active" 
                        className="filled-in"
                        label={ t('screens-user-list-active') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="active"
                    />             
                </div>

                <div className="row margin">
                    <Field type="text" name="email" id="email-selector" 
                        label={ t('screens-user-list-email') } 
                        component={ renderFieldOneColumn } 
                        icon="email" 
                        key="email-selector" 
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>
            </form>
        );
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-list-acciones') }</th>
                        <th>{ t('screens-user-selector-username') }</th>
                        <th>{ t('screens-user-selector-email') }</th>
                        <th>{ t('screens-user-selector-active') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>
                                    <ActionButton icon="arrow_forward"
                                        tooltip={ t('screens-list-acciones-select') } 
                                        extraClass="pink darken-4 ml-3" 
                                        onClick={ () => onSelect(item) } />
                                </td>
                                <td>{ item.username }</td>
                                <td>{ item.email }</td>
                                <td>{ item.active }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-user-selector-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'userSelectorForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserSelector);