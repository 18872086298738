import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import i18n from 'i18next';

import './Login.css';

import { forgetPassword } from '../../actions/auth';

import { showError, showSuccess } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    icon,
    id,
    key
}) => (

    <div className="row margin">
        <div className="input-field col s12">
            <i className="material-icons prefix pt-2">{ icon }</i>
            <input { ...input } key={key} id={ id } type={ type } />
            <label htmlFor={input.name} className="center-align">
                { label }
            </label>
            <small className="errorTxt6">
                <div id="crole-error" className="error">
                    {touched && error}
                </div>
            </small>
        </div>
    </div>

);

const validate = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = i18n.t('validator-error-email');
    }

    return errors;
}

const ScreensUserForgetPassword = ({ history, submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleForgetPassword = (values) => {
        dispatch(
            forgetPassword({
                ...values, 
                email: stringTrim(values.email)
            })
        ).then((res) => {
            if (res.ok) {
                history.replace('/login');
                showSuccess(t('message-title-success'), t('message-review-email'));
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const renderForm = () => {

        return (
            <>
                <form className="login-form" id="forget-password-form" onSubmit={ handleSubmit(handleForgetPassword) }>
                    <div className="row">
                        <div className="input-field col s12">
                            <h5 className="ml-4 valign-wrapper">
                                <img src="/assets/images/logo.png" alt="Instituto de Constelaciones Familiares Brigitte Champetier de Ribes" className="responsive-img" style={{maxWidth: "50px", "marginRight": "15px"}} />
                                { t('forget_password_title') }
                            </h5>
                        </div>
                    </div>

                    <Field type="text" name="email" id="email" 
                        label={ t('input_email') } 
                        component={ renderField } 
                        icon="person_outline" 
                        key="email"
                    />

                    <div className="row">
                        <div className="input-field col s12">
                            <button type="submit" className="btn waves-effect waves-light pink darken-4 col s12" disabled={ submitting }>
                                { t('form-button-send') }
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );

    };

    return (
        <div className="row">
            <div className="col s12">
                <div className="container">
                    <div id="login-page" className="row">
                        <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                
                            { renderForm() }
                
                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'forgetPasswordForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserForgetPassword);