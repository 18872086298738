import React from 'react';

const SortingHeader = ({ title, direction, onClick }) => {
    return (
        <th 
            className={`sorting${ (direction) ? `_${direction.toLowerCase()}` : '' }`}
            onClick={ onClick }
        >
            { title }
        </th>
    );
};

export default SortingHeader;