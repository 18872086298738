import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchTaskList = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/tareas/list', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, data: data }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

export const fetchTasks = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/tareas', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setTasks(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setTasks = (data) => ({
   type: TYPES.SET_TASKS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/tareas/excel', 
                'tareas.xlsx',
                filter, {
                    titulo: 'Tareas',
                    descripcion: 'Tareas'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'tareas.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectTask = (data) => ({
    type: TYPES.SELECT_TASK, payload: data
});

export const setTaskFilter = (filter) => ({
    type: TYPES.SET_TASK_FILTER, payload: filter
});

export const deleteTask = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/tareas/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendTask = (task) => {
    return async () => {
        try {
            let res;

            if (task.Tareas.id) {
                res = await apiClientWithToken().put(
                    `/tareas/${task.Tareas.id}`, task
                );
            } else {
                res = await apiClientWithToken().post(
                    '/tareas', task
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};