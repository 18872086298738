import TYPES from '../actions/types';

const INITIAL_STATE = {
    list: [],
    paging: {},
    filter: {},
    selected: null
};

export const studentDiplomaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPES.SET_STUDENT_DIPLOMAS:
            const { count, current, page, pageCount, sort, direction } = action.payload.paging;
            return {
                ...state, 
                list: action.payload.list, 
                paging: { count, current, page, pageCount, sort, direction }
            };

        case TYPES.SELECT_STUDENT_DIPLOMA:
            return {
                ...state,
                selected: action.payload
            };

        case TYPES.SET_STUDENT_DIPLOMA_FILTER:
            return {
                ...state,
                filter: action.payload,
                paging: {}
            };
        
        default:
            return state;
    }
};