import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../../helpers/messages';

import TaskStudentSearchForm from './SearchForm';
import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Pagination from '../../../components/UI/Pagination';
import ExcelButton from '../../../components/UI/ExcelButton';
import Spacer from '../../../components/UI/Spacer';
import ActionButton from '../../../components/UI/ActionButton';
import SortingHeader from '../../../components/UI/SortingHeader';
import FloatingButtonAction from '../../../components/UI/FloatingButtonAction';

import { 
    fetchStudentTasks, 
    downloadExcel,
    selectStudentTask,
    deleteStudentTask,
    approveTask } from '../../../actions/studentTask';
import { startLoading, endLoading } from '../../../actions/ui';

const ScreensTaskStudentList = ({ nombre, apellidos, titulo, tareas_estado_id }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.studentTask);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentTasks(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-student-task-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteStudentTask(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                nombre: nombre,
                apellidos: apellidos,
                titulo: titulo,
                tareas_estado_id: tareas_estado_id
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleAdd = () => {
        history.push('/tareas/alumnos/formulario');
    };

    const handleFiles = (item) => {
        dispatch(selectStudentTask(item));
        history.push('/tareas/alumnos/archivos');
    };

    const handleApprove = (item, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-aprobe-student-task-text', { name }),
            t('button-title-approve'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    approveTask(item)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    }

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-student-task-list-nombre') }
                            direction={ (sort === 'nombre') ? direction : '' }
                            onClick={ () => handleSort('nombre') }
                        />
                        <SortingHeader title={ t('screens-student-task-list-apellidos') }
                            direction={ (sort === 'apellidos') ? direction : '' }
                            onClick={ () => handleSort('apellidos') }
                        />
                        <SortingHeader title={ t('screens-student-task-list-titulo') }
                            direction={ (sort === 'titulo') ? direction : '' }
                            onClick={ () => handleSort('titulo') }
                        />
                        <th>{ t('screens-student-task-list-estado') }</th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.alumno.nombre }</td>
                                <td>{ item.alumno.apellidos }</td>
                                <td>{ item.tarea.titulo }</td>
                                <td>
                                    <span className={ (item.tareas_estado.nombre === 'Aprobado') ? 'badge green darken-2' : ((item.tareas_estado.nombre === 'Entregado') ? 'badge orange darken-2' : 'badge red darken-2') }>
                                        { t(item.tareas_estado.nombre) }
                                    </span>
                                </td>
                                <td>
                                    <Can perform="TareasAlumnos:adjuntarArchivo" yes={() => (
                                        <ActionButton icon="attach_file"
                                        tooltip={ t('screens-list-acciones-archivos') } 
                                        extraClass="blue darken-2 ml-3" 
                                        onClick={ () => handleFiles(item) } />
                                    )} />
                                    {
                                        (item.tareas_estado.nombre === 'Aprobado')
                                        ? ''
                                        : <Can perform="TareasAlumnos:delete" yes={() => (
                                            <ActionButton icon="delete_forever"
                                            tooltip={ t('screens-list-acciones-delete') } 
                                            extraClass="red ml-3" 
                                            onClick={ () => handleDelete(item.id, `${item.tarea.titulo} - ${item.alumno.nombre} ${item.alumno.apellidos}`) } />
                                        )} />
                                    }
                                    {
                                        (item.tareas_estado.nombre === 'Entregado')
                                        ? <Can perform="TareasAlumnos:aprobar" yes={() => (
                                            <ActionButton icon="done"
                                            tooltip={ t('screens-list-acciones-aprobar') } 
                                            extraClass="green darken-2 ml-3" 
                                            onClick={ () => handleApprove(item, `${item.tarea.titulo} - ${item.alumno.nombre} ${item.alumno.apellidos}`) } />
                                        )} />
                                        : ''
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={ t('screens-student-task-list-title') }>
                <Can perform="TareasAlumnos:excel" yes={() => (
                    <ExcelButton onPressButton={ handleExcel } />
                )} />
            </PageTitle>

            <TaskStudentSearchForm />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />

            <Can perform="TareasAlumnos:add" yes={() => (
                <FloatingButtonAction icon="add" 
                    title={ t('screens-list-acciones-add') } action={ handleAdd }
                />
            )} />

        </PageContainer>
    );

};

const formSelector = formValueSelector('studentTaskSearchForm');

export default connect(state => ({
    nombre: formSelector(state, 'nombre'),
    apellidos: formSelector(state, 'apellidos'),
    titulo: formSelector(state, 'titulo'),
    tareas_estado_id: formSelector(state, 'tareas_estado_id')
}))(ScreensTaskStudentList);