// Date a yyyy-mm-dd
export const datepickerToDate = (inDate) => {
    if (inDate) {
        const mounth = (inDate.getMonth() > 8) 
            ? `${inDate.getMonth() + 1}` 
            : `0${inDate.getMonth() + 1}`;
        const day = (inDate.getDate() > 9) ? `${inDate.getDate()}` : `0${inDate.getDate()}`;
        return `${inDate.getFullYear()}-${mounth}-${day}`;
    } else {
        return inDate;
    }
};

// yyyy-mm-dd a dd/mm/yyyy
export const dateToDatepicker = (inDate) => {
    if (inDate) {
        const parts = inDate.split('-');
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    } else {
        return inDate;
    }
};

// dd/mm/yyyy a yyyy-mm-dd
export const dateToDateDB = (inDate) => {
    if (inDate) {
        const parts = inDate.split('/');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else {
        return inDate;
    }
};

// Trim de cadenas de texto.
export const stringTrim = (value) => {
    if (value) {
        if (isNaN(value)) {
            return value.trim();
        } else {
            return value;
        }
    }
    return value;
};
