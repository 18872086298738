import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import Divider from '../../components/UI/Divider';

import { dateToDatepicker } from '../../helpers/converter';

const ScreensCourseView = () => {

    const { t } = useTranslation();

    const { selected } = useSelector(state => state.course);

    const history = useHistory();

    const handleCancel = () => {
        history.goBack();        
    };

    const renderData = () => {
        let itemsAdded = 0;
        const items = [];

        items.push(
            <div className="col s12 m6">
                <i className="material-icons">watch_later</i>
                <label className="active ml-1">{ t('screens-course-list-horas') }</label>
                <p>{selected && selected.horas}</p>
            </div>
        );
        itemsAdded++;

        if (selected && (selected.cursos_tipo.nombre === 'Presencial' || selected.cursos_tipo.nombre === 'Taller' || selected.cursos_tipo.nombre === 'Videoconferencia')) {
            items.push(
                <div className="col s12 m6">
                    <i className="material-icons">event</i>
                    <label className="active ml-1">{ t('screens-course-list-fecha_inicio') }</label>
                    <p>{selected && dateToDatepicker(selected.fecha_inicio)}</p>
                </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">person</i>
                        <label className="active ml-1">{ t('screens-course-list-organizador') }</label>
                        <p>{selected && (selected.organizador && `${selected.organizador.nombre} ${selected.organizador.apellidos}`)}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
        }

        if (selected && (selected.cursos_tipo.nombre === 'Presencial' || selected.cursos_tipo.nombre === 'Taller')) {
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">event</i>
                        <label className="active ml-1">{ t('screens-course-list-fecha_fin') }</label>
                        <p>{selected && dateToDatepicker(selected.fecha_fin)}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">location_on</i>
                        <label className="active ml-1">{ t('screens-course-list-demarcacion') }</label>
                        <p>{selected && (selected.demarcacion && selected.demarcacion.nombre)}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">person_pin_circle</i>
                        <label className="active ml-1">{ t('screens-course-list-formador') }</label>
                        <p>{selected && (selected.formadores_homologado && `${selected.formadores_homologado.alumno.nombre} ${selected.formadores_homologado.alumno.apellidos}`)}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            // items.push(
            //     <div className="col s12 m6">
            //             <i className="material-icons">watch</i>
            //             <label className="active ml-1">{ t('screens-course-list-horas_asistencia') }</label>
            //             <p>{selected && selected.horas_asistencia}</p>
            //         </div>
            // );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">business</i>
                        <label className="active ml-1">{ t('screens-course-list-lugar') }</label>
                        <p>{selected && selected.lugar}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">location_city</i>
                        <label className="active ml-1">{ t('screens-course-list-ciudad') }</label>
                        <p>{selected && selected.ciudad}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">map</i>
                        <label className="active ml-1">{ t('screens-course-list-pais') }</label>
                        <p>{selected && selected.pais}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">monetization_on</i>
                        <label className="active ml-1">{ t('screens-course-list-precio') }</label>
                        <p>{selected && selected.precio}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">event_seat</i>
                        <label className="active ml-1">{ t('screens-course-list-plazas') }</label>
                        <p>{selected && selected.plazas}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">airline_seat_recline_normal</i>
                        <label className="active ml-1">{ t('screens-course-list-ocupadas') }</label>
                        <p>{selected && selected.ocupadas}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
            items.push(
                <div className="col s12 m6">
                        <i className="material-icons">school</i>
                        <label className="active ml-1">{ t('screens-course-list-ciclo_formativo') }</label>
                        <p>{selected && selected.ciclo_formativo}</p>
                    </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
        }

        if (selected && (selected.cursos_tipo.nombre === 'Online')) {
            items.push(
                <div className="col s12 m6">
                    <i className="material-icons">book</i>
                    <label className="active ml-1">{ t('screens-course-list-num_unidad') }</label>
                    <p>{selected && selected.num_unidad}</p>
                </div>
            );
            itemsAdded++;
            if (itemsAdded % 2 === 0) {
                items.push(<Divider />);
            }
        }

        return (
            <div>
                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">bookmark</i>
                        <label className="active ml-1">{ t('screens-course-list-tipo') }</label>
                        <p>{selected && selected.cursos_tipo.nombre}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">text_fields</i>
                        <label className="active ml-1">{ t('screens-course-list-titulo') }</label>
                        <p>{selected && selected.titulo}</p>
                    </div>
                </div>

                <Divider />

                <div className="row mb-2">
                    { items.map(item => item) }
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="reset"
                            title={ t('screens-list-acciones-volver') }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <PageContainer>    
            <PageTitle title={ t('screens-course-form-title') } />

            <ContentContainer>
                { renderData() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

export default ScreensCourseView;