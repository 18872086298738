import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentDocuments = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/documentos-alumnos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentDocuments(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentDocuments = (data) => ({
   type: TYPES.SET_STUDENT_DOCUMENTS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/documentos-alumnos/excel', 
                'documentos-alumnos.xlsx',
                filter,{
                    'nombre': 'Alumnos',
                    'apellidos': 'Alumnos',
                    'email': 'Alumnos',
                    'nombre_original': 'Documentos',
                    'descripcion' :'Documentos'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'documentos-alumnos.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const setStudentDocumentFilter = (filter) => ({
    type: TYPES.SET_STUDENT_DOCUMENT_FILTER, payload: filter
});

export const deleteStudentDocument = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/documentos-alumnos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudentDocument = (studentDocument) => {
    return async () => {
        try {
            const res = await apiClientWithToken().post(
                '/documentos-alumnos', studentDocument
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};