import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import Divider from '../../components/UI/Divider';
import ActionButton from '../../components/UI/ActionButton';

import { 
    downloadFoto,
    downloadAdjunto
} from '../../actions/student';

import { dateToDatepicker } from '../../helpers/converter';

const ScreensStudentView = () => {

    const { t } = useTranslation();

    const { selected } = useSelector(state => state.student);

    const dispatch = useDispatch();

    const history = useHistory();

    const handleCancel = () => {
        history.goBack();
    };

    const handleDownloadFoto = (alumno) => {
        dispatch(downloadFoto(alumno));
    };

    const handleDownloadAdjunto = (alumno) => {
        dispatch(downloadAdjunto(alumno));
    };
    
    const renderData = () => {
        return (
            <div>
                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">person_outline</i>
                        <label className="active ml-1">{ t('screens-student-list-nombre') }</label>
                        <p>{selected && selected.nombre}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">person</i>
                        <label className="active ml-1">{ t('screens-student-list-apellidos') }</label>
                        <p>{selected && selected.apellidos}</p>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">email</i>
                        <label className="active ml-1">{ t('screens-student-list-email') }</label>
                        <p>{selected && selected.email}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">location_searching</i>
                        <label className="active ml-1">{ t('screens-student-list-demarcacion') }</label>
                        <p>
                            {
                                (selected && (selected.demarcacion && selected.demarcacion.nombre))
                                ? selected.demarcacion.nombre
                                : '-'
                            }
                        </p>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-acepta_politica_privacidad') }</label>
                        <p>{
                            (selected && (selected.user && selected.user.acepta_politica_privacidad))
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : '-'
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-acepta_emails') }</label>
                        <p>{
                            (selected && (selected.user && selected.user.acepta_emails))
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : '-'
                        }</p>
                    </div>
                </div>

                <Divider />

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">fingerprint</i>
                        <label className="active ml-1">{ t('screens-student-list-dni') }</label>
                        <p>{selected && selected.dni}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">build</i>
                        <label className="active ml-1">{ t('screens-student-list-trabajo') }</label>
                        <p>{selected && selected.trabajo}</p>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">phone</i>
                        <label className="active ml-1">{ t('screens-student-list-telefono_fijo') }</label>
                        <p>{selected && selected.telefono_fijo}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">phone_android</i>
                        <label className="active ml-1">{ t('screens-student-list-telefono_movil') }</label>
                        <p>{selected && selected.telefono_movil}</p>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">contact_mail</i>
                        <label className="active ml-1">{ t('screens-student-list-direccion') }</label>
                        <p>{selected && selected.direccion}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">location_city</i>
                        <label className="active ml-1">{ t('screens-student-list-poblacion') } </label>
                        <p>{selected && selected.poblacion}</p>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">map</i>
                        <label className="active ml-1">{ t('screens-student-list-provincia') }</label>
                        <p>{selected && selected.provincia}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">domain</i>
                        <label className="active ml-1">{ t('screens-student-list-codigo_postal') }</label>
                        <p>{selected && selected.codigo_postal}</p>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">map</i>
                        <label className="active ml-1">{ t('screens-student-list-pais') }</label>
                        <p>{selected && selected.pais}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">assignment</i>
                        <label className="active ml-1">{ t('screens-student-list-otros_datos') }</label>
                        <p>{selected && selected.otros_datos}</p>
                    </div>
                </div>

                <Divider />

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-list-acciones-foto') }</label>
                        <p>
                            {
                                (selected && selected.foto)
                                ? <Can perform="Alumnos:downloadFoto" yes={() => (
                                    <ActionButton icon="photo_camera" 
                                        tooltip={ t('screens-list-acciones-foto') }
                                        extraClass="grey darken-2 ml-3" 
                                        onClick={ () => handleDownloadFoto(selected) } />
                                    )} />
                                : '-'
                            }
                        </p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-list-acciones-adjunto') }</label>
                        <p>
                            {
                                (selected && selected.adjunto)
                                ? <Can perform="Alumnos:downloadAdjunto" yes={() => (
                                    <ActionButton icon="attach_file" 
                                        tooltip={ t('screens-list-acciones-adjunto') }
                                        extraClass="grey darken-2 ml-3" 
                                        onClick={ () => handleDownloadAdjunto(selected) } />
                                    )} />
                                : '-'
                            }
                        </p>
                    </div>
                </div>

                <Divider />

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-formacion_previa_acreditada') }</label>
                        <p>{
                            (selected && selected.formacion_previa_acreditada)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-constelador_acreditado') }</label>
                        <p>{
                            (selected && selected.constelador_acreditado)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-es_constelador') }</label>
                        <p>{
                            (selected && selected.es_constelador)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-capacidad_consteladora') }</label>
                        <p>{
                            (selected && selected.capacidad_consteladora)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-master') }</label>
                        <p>{
                            (selected && selected.master)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-maestria') }</label>
                        <p>{
                            (selected && selected.maestria)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-especialista') }</label>
                        <p>{
                            (selected && selected.especialista)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                    <div className="col s12 m6">
                        <label className="active ml-1">{ t('screens-student-list-experto') }</label>
                        <p>{
                            (selected && selected.experto)
                            ? <i className="material-icons orange-text text-darken-2">check</i>
                            : <i className="material-icons orange-text text-darken-2">remove</i>
                        }</p>
                    </div>
                </div>

                <Spacer height="2" />

                <div className="row mb-2">
                    <div className="col s12 m6">
                        <i className="material-icons">event</i>
                        <label className="active ml-1">{ t('screens-student-list-comienzo') }</label>
                        <p>{dateToDatepicker(selected && selected.comienzo)}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">event</i>
                        <label className="active ml-1">{ t('screens-student-list-fecha_capacidad_consteladora') }</label>
                        <p>{dateToDatepicker(selected && selected.fecha_capacidad_consteladora)}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">event</i>
                        <label className="active ml-1">{ t('screens-student-list-fecha_master') }</label>
                        <p>{dateToDatepicker(selected && selected.fecha_master)}</p>
                    </div>
                    <div className="col s12 m6">
                        <i className="material-icons">books</i>
                        <label className="active ml-1">{ 'Libros Comentados' }</label>
                        <p>{selected && selected.libros_comentados}</p>
                    </div>
                </div>

                <Divider />

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="reset"
                            title={ t('screens-list-acciones-volver') }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <PageContainer>    
            <PageTitle title={ t('screens-student-form-title') } />

            <ContentContainer>
                { renderData() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

export default ScreensStudentView;