import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentList = (filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                '/alumnos/list', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, data: data }
            } else {
                return { ok: false, message: data.message };
            }
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

export const fetchStudentListNoSocios = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/alumnos/listNoSocios', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, data: data }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

export const fetchStudentListNoFormadores = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/alumnos/listNoFormadores', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, data: data }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

export const fetchStudents = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/alumnos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudents(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudents = (data) => ({
   type: TYPES.SET_STUDENTS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {
            const res = await downloadExcelFile('/alumnos/excel', 'alumnos.xlsx', filter, {
                nombre: 'Alumnos',
                apellidos: 'Alumnos',
                email: 'Alumnos',
                pais: 'Alumnos',
                demarcacion_id: 'Alumnos',
                cursos_tipo_id: 'Alumnos',
                horas_desde: 'Alumnos',
                horas_hasta: 'Alumnos'
            });
            const blob = await res.blob();
            fileDownload(blob, 'alumnos.xlsx');
            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectStudent = (data) => ({
    type: TYPES.SELECT_STUDENT, payload: data
});

export const setStudentFilter = (filter) => ({
    type: TYPES.SET_STUDENT_FILTER, payload: filter
});

export const deleteStudent = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/alumnos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudent = (student) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(student).forEach(([key, value]) => {
                if (value) {
                    formData.append(`Alumnos[${key}]`, value);
                }
            });

            if (student.id) {
                res = await apiClientWithToken().post(
                    `/alumnos/edit/${student.id}`,
                    formData,
                    {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'AuthToken': localStorage.getItem('token') || 'notoken'
                        }
                    }
                );
            } else {
                res = await apiClientWithToken().post(
                    '/alumnos', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'AuthToken': localStorage.getItem('token') || 'notoken'
                        }
                    }
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudentDegrees = (student_id, titulaciones) => {
    return async () => {
        try {
            const res = await apiClientWithToken().post(
                `/alumnos/editTitulaciones/${student_id}`, titulaciones
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const fetchStudentTitulaciones = (alumno_id, filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                `/alumnos/titulaciones/${alumno_id}`, { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentTitulaciones(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentTitulaciones = (data) => ({
   type: TYPES.SET_STUDENT_TITULACIONES, payload: data
});

export const downloadFoto = (alumno) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/alumnos/downloadFoto/${ alumno.id }`,  alumno.foto
            );
            const blob = await res.blob();
            fileDownload(blob, alumno.foto);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const downloadAdjunto = (alumno) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/alumnos/downloadAdjunto/${ alumno.id }`,  alumno.adjunto
            );
            const blob = await res.blob();
            fileDownload(blob, alumno.adjunto);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudents = (students) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(students).forEach(([key, value]) => {
                if (value) {
                    formData.append(`Alumnos[${key}]`, value);
                }
            });

            res = await apiClientWithToken().post(
                `/alumnos/load`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'AuthToken': localStorage.getItem('token') || 'notoken'
                    }
                }
            );
            
            const { ok, result: { data } } = res.data;
            
            if (ok) {
                return { ok: true, message: data.message };
            } else {                
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};