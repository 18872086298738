import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { reduxForm } from 'redux-form';
import i18n from 'i18next';
import M from 'materialize-css';

import Can from '../../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../../helpers/messages';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Pagination from '../../../components/UI/Pagination';
import Spacer from '../../../components/UI/Spacer';
import ActionButton from '../../../components/UI/ActionButton';
import SortingHeader from '../../../components/UI/SortingHeader';
import FloatingButtonAction from '../../../components/UI/FloatingButtonAction';
import FormButton from '../../../components/UI/FormButton';

import { 
    fetchStudentTaskDocuments,
    sendDocument, 
    deleteStudentTaskDocument,
    download } from '../../../actions/studentTaskDocument';
import { startLoading, endLoading } from '../../../actions/ui';

const ScreensTaskStudentDocuments = ({ submitting, handleSubmit, dirty }) => {

    const fileInput = React.createRef();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { selected } = useSelector(state => state.studentTask);

    const { list, paging, filter } = useSelector(state => state.studentTaskDocument);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentTaskDocuments(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});

        fetchData({ ...paging, ...filter, tareas_alumno_id: (selected) ? selected.id : 0 });
    }, [dispatch, filter, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, tareas_alumno_id: selected.id, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, tareas_alumno_id: selected.id, sort: name, direction: newDirection });
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-student-task-document-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteStudentTaskDocument(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, tareas_alumno_id: selected.id, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleAdd = () => {
        document.getElementById('tarea-archivo-form').reset(); 

        const elem = document.getElementById('modal-selector-archivo');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleUpload = (values) => {
        const archivo = fileInput.current.files[0];

        if (archivo === undefined || !archivo) {
            showError(t('message-title-error'), t('message-error-no-file'));
        } else {
            dispatch(startLoading());

            dispatch(
                sendDocument({ tareas_alumno_id: selected.id, nombre_original: archivo })
            ).then((res) => {
                dispatch(endLoading());

                if (res.ok) {
                    fetchData({ ...paging, ...filter, tareas_alumno_id: selected.id, page: 1 });
                    handleCancel();
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });
        }
    };

    const handleCancel = () => {
        const elem = document.getElementById('modal-selector-archivo');
        const instance = M.Modal.getInstance(elem);
        instance.close();
    };

    const handleDownload = (item) => {
        dispatch(download(item));
    };

    const handleBack = () => {
        history.goBack();
    }

    const renderTable = () => {
        if (!selected) {
            history.goBack('/tareas');
            return (<></>);
        }

        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-student-task-document-list-archivo') }
                            direction={ (sort === 'nombre_original') ? direction : '' }
                            onClick={ () => handleSort('nombre_original') }
                        />
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.nombre_original }</td>
                                <td>
                                    <Can perform="TareasAlumnosDocumentos:descargar" yes={() => (
                                        <ActionButton icon="file_download"
                                        tooltip={ t('screens-list-acciones-download') } 
                                        extraClass="green darken-2 ml-3" 
                                        onClick={ () => handleDownload(item) } />
                                    )} />
                                    {
                                        (selected && selected.tareas_estado.nombre === 'Aprobado')
                                        ? ''
                                        : <Can perform="TareasAlumnosDocumentos:delete" yes={() => (
                                            <ActionButton icon="delete_forever"
                                            tooltip={ t('screens-list-acciones-delete') } 
                                            extraClass="red ml-3" 
                                            onClick={ () => handleDelete(item.id, `${item.nombre_original}`) } />
                                        )} />
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={ t('screens-student-task-document-list-title') }>
                <ActionButton icon="arrow_back"
                    tooltip={ t('screens-list-acciones-volver') } 
                    extraClass="grey text-black ml-3" 
                    onClick={ () => handleBack() } />
            </PageTitle>
            <blockquote className="black-text">
                {
                    (selected)
                    ? <>
                        <p>{ `${t('screens-student-task-document-alumno')}: ${selected.alumno.nombre} ${selected.alumno.apellidos}` }</p>
                        <p>{ `${t('screens-student-task-document-tarea')}: ${selected.tarea.titulo}` }</p>
                    </>
                    : <></>
                }
            </blockquote>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />

            {
                (selected.tareas_estado.nombre === 'Aprobado')
                ? ''
                : <Can perform="TareasAlumnosDocumentos:add" yes={() => (
                    <FloatingButtonAction icon="add" 
                        title={ t('screens-list-acciones-add') } action={ handleAdd }
                    />
                )} />
            }

            <div id="modal-selector-archivo" className="modal">
                <div className="modal-content">
                    <h5>{ t('selector-student-task-document-title') }</h5>
                    <form onSubmit={ handleSubmit(handleUpload) } id="tarea-archivo-form">
                        <div className={`input-field file-field col s12`}>
                            <div className="btn">
                                <span>{ t('screens-document-list-nombre-original') }</span>
                                <input name="nombre_original" type="file" key="nombre_original" id="nombre_original" ref={ fileInput } />
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path validate" type="text" />
                            </div>
                            <small className="errorTxt6">
                                <div id="crole-error" className="error"></div>
                            </small>      
                        </div>
                        
                        <div className="row">
                            <div className="input-field col s12">
                                <FormButton type="submit"
                                    title={ t('form-button-send') }
                                    disabled={ submitting }
                                />

                                <FormButton type="reset"
                                    title={ t('form-button-cancel') }
                                    disabled={ submitting }
                                    onClick={ handleCancel }
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.nombre_original) {
        errors.nombre_original = i18n.t('validator-error-archivo');
    }

    return errors;
};

export default reduxForm({
    form: 'studentTaskDocumentForm',
    validate: validate,
    destroyOnUnmount: false, //true
})(ScreensTaskStudentDocuments);