import React from 'react';

const Spacer = ({ height }) => {
    return (
        <div className="row">
            <div className="col s12">
                    <p className={ `mb-${ height }` }></p>
            </div>
        </div>
    );
};

export default Spacer;