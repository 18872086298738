import TYPES from './types';
import { apiClientWithToken, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentTaskDocuments = (filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                '/tareas-alumnos-documentos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentTaskDocuments(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentTaskDocuments = (data) => ({
   type: TYPES.SET_STUDENT_TASK_DOCUMENTS, payload: data
});

export const deleteStudentTaskDocument = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/tareas-alumnos-documentos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendDocument = (document) => {
    return async () => {
        try {
            let res;
            const formData = new FormData();
            Object.entries(document).forEach(([key, value]) => {
                if (value) {
                    formData.append(`TareasAlumnosDocumentos[${key}]`, value);
                }
            });

            res = await apiClientWithToken().post(
                '/tareas-alumnos-documentos', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'AuthToken': localStorage.getItem('token') || 'notoken'
                    }
                }
            );
            
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const download = (document) => {
    return async () => {
        try {

            const res = await downloadFile(
                `/tareas-alumnos-documentos/download/${ document.id }`, 
                document.nombre_original
            );
            const blob = await res.blob();
            fileDownload(blob, document.nombre_original);

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};