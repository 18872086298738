import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Waiting from '../components/UI/Waiting';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import ScreensUserLogin from '../screens/User/Login';
import ScreensUserForgetPassword from '../screens/User/ForgetPassword';
import ScreensUserResetPassword from '../screens/User/ResetPassword';
import ScreensUserFirstAccess from '../screens/User/FirstAccess';

import DashboardRoutes from './DashboardRoutes';

import { startChecking } from '../actions/auth';

const AppRouter = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { checking, user } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(startChecking());
    }, [dispatch]);

    if (checking) {
        return (
            <div className="row valign-wrapper" style={{ height: '100vh'}}>
                <div className="col s-2 offset-s5 center-align">
                    <Waiting title={ t('waiting') } />
                </div>
            </div>
        );
    }

    return (        
        <Router>
            <div>
                <Switch>
                    {/* Rutas públicas */}
                    <PublicRoute exact 
                        path="/login" 
                        component={ ScreensUserLogin } 
                        isAuthenticated={ !!user }
                    />

                    <PublicRoute exact 
                        path="/olvide-password" 
                        component={ ScreensUserForgetPassword } 
                        isAuthenticated={ !!user }
                    />

                    <PublicRoute exact 
                        path="/primer-acceso" 
                        component={ ScreensUserFirstAccess } 
                        isAuthenticated={ !!user }
                    />

                    <PublicRoute 
                        path="/restaurar-password/:token" 
                        component={ ScreensUserResetPassword } 
                        isAuthenticated={ !!user }
                    />

                    {/* Rutas privadas */}
                    <PrivateRoute
                        path="/"
                        component={ DashboardRoutes }
                        isAuthenticated={ !!user }
                    />

                </Switch>
            </div>
        </Router>  
    );
};


//-----------------------
export default AppRouter;

/*
const BODY_CLASS_LIST = [
    '1-column',
    'login-bg',
    'blank-page',
    'blank-page'
];
const BODY_ATTRIBUTES = {
    'data-col': '1-column'
};


componentDidMount() {

        // Body properties.
        document.body.classList.add(...BODY_CLASS_LIST);
        
        for (const key of Object.keys(BODY_ATTRIBUTES)) {
            document.body.setAttribute(key, BODY_ATTRIBUTES[key]);
        }

    }


    componentWillUnmount() {

        // Body properties.
        document.body.classList.remove(...BODY_CLASS_LIST);
  
        for (const key of Object.keys(BODY_ATTRIBUTES)) {
            document.body.removeAttribute(key, BODY_ATTRIBUTES[key]);
        }

    }
*/
