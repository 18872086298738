import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';

import { renderFieldOneColumn, renderFieldTwoColumns, renderSelectFieldTwoColumns } from '../../components/UI/Fileds';

import { sendArea } from '../../actions/area';
import { startLoading, endLoading, fetchDemarcacionRaizList } from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const ScreensAreaForm = ({ submitting, handleSubmit, reset, dirty }) => {
    
    const { t } = useTranslation();

    const { selected } = useSelector(state => state.area);

    const { demarcacionList } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchDemarcacionRaizList()).then((res) => {    
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(initialize('areaForm', {
                id: selected.id,
                nombre: selected.nombre,
                parent_id: selected.parent_id ? selected.parent_id : 0
            }));
        } else {
            dispatch(initialize('areaForm', { nombre: '' }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {
        dispatch(startLoading());

        dispatch(
            sendArea({Demarcaciones: {
                ...values,
                nombre: stringTrim(values.nombre)
            }})
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-area-list-nombre') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="nombre"
                    />

                    <Field name="parent_id" id="parent_id" 
                        label={ t('screens-organizer-list-demarcacion_padre') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="location_searching" 
                        key="parent_id" 
                    >
                        <option value="0">{ t('selector-area-no-selected') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-area-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = i18n.t('validator-error-nombre');
    }

    return errors;
};

export default reduxForm({
    form: 'areaForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensAreaForm);