import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';

import Can from '../../../routes/Can';

import { showError } from '../../../helpers/messages';

import StudentDiplomaSearchForm from './SearchForm';
import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Pagination from '../../../components/UI/Pagination';
import ExcelButton from '../../../components/UI/ExcelButton';
import Spacer from '../../../components/UI/Spacer';
import SortingHeader from '../../../components/UI/SortingHeader';
import PdfButton from '../../../components/UI/PdfButton';

import { 
    fetchStudentDiplomas, 
    downloadExcel, 
    selectStudentDiploma,
    download
} from '../../../actions/studentDiploma';
import { 
    startLoading, 
    endLoading
} from '../../../actions/ui';

import { dateToDatepicker } from '../../../helpers/converter';

const ScreensStudentDiplomaList = ({ ciudad, diplomas_tipo_id, nombre, apellidos, fecha }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.studentDiploma);
    const { sort, direction } = paging;

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentDiplomas(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    useEffect(() => {
        dispatch(selectStudentDiploma(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                ciudad: ciudad,
                diplomas_tipo_id: diplomas_tipo_id,
                nombre: nombre,
                apellidos: apellidos,
                fecha: fecha
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleDownload = (id) => {
        dispatch(download(id));
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-student-diploma-list-apellidos') }
                            direction={ (sort === 'apellidos') ? direction : '' }
                            onClick={ () => handleSort('apellidos') }
                        />
                        <SortingHeader title={ t('screens-student-diploma-list-nombre') }
                            direction={ (sort === 'nombre') ? direction : '' }
                            onClick={ () => handleSort('nombre') }
                        />
                        <SortingHeader title={ t('screens-diploma-list-ciudad') }
                            direction={ (sort === 'ciudad') ? direction : '' }
                            onClick={ () => handleSort('ciudad') }
                        />
                        <SortingHeader title={ t('screens-diploma-list-fecha') }
                            direction={ (sort === 'fecha') ? direction : '' }
                            onClick={ () => handleSort('fecha') }
                        />
                        <th>
                            { t('screens-diploma-list-tipo') }
                        </th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.alumno.apellidos }</td>
                                <td>{ item.alumno.nombre }</td>
                                <td>{ item.diploma.ciudad }</td>
                                <td>{ dateToDatepicker(item.diploma.fecha) }</td>
                                <td>{ item.diploma.diplomas_tipo.nombre }</td>
                                <td>
                                    {
                                        (item.diploma.tiene_documento)
                                        ? <PdfButton 
                                            onPressButton={ () => handleDownload(item.id) } />
                                        : ''
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };
    
    return (
        <PageContainer>

            <PageTitle title={ t('screens-student-diploma-list-title') }>
                <Can perform="AlumnosDiplomas:excel" yes={() => (
                    <ExcelButton onPressButton={ handleExcel } />
                )} />
            </PageTitle>

            <StudentDiplomaSearchForm />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />

        </PageContainer>
    );

};

const formSelector = formValueSelector('studentDiplomaSearchForm');

export default connect(state => ({
    nombre: formSelector(state, 'nombre'),
    apellidos: formSelector(state, 'apellidos'),
    fecha: formSelector(state, 'fecha'),
    ciudad: formSelector(state, 'ciudad'),
    diplomas_tipo_id: formSelector(state, 'diplomas_tipo_id')
}))(ScreensStudentDiplomaList);