import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, change, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';
import ScreensCourseSelector from '../../Course/Selector';
import ScreensDocumentSelector from '../../Document/Selector';

import { renderFieldOneColumn } from '../../../components/UI/Fileds';

import { sendCourseDocument } from '../../../actions/courseDocument';
import { startLoading, endLoading } from '../../../actions/ui';
import { fetchCourseList } from '../../../actions/course';
import { fetchDocumentList } from '../../../actions/document';

import { showError, showConfirmation } from '../../../helpers/messages';

const ScreensDocumentCourseForm = ({ submitting, handleSubmit, dirty }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const [course, setCourse] = useState({ id: '', label: t('selector-course-no-selected') });
    
    const [documento, setDocument] = useState({ id: '', label: t('selector-document-no-selected') });

    useEffect(() => {
        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
        
        dispatch(initialize('courseDocumentForm', { curso_id: '', documento_id: '' }));
    }, [dispatch]);

    const handleSend = (values) => {
        if (!values.curso_id) {
            showError(t('message-title-error'), t('message-error-no-course-selected'));
        } else if (!values.documento_id) {
            showError(t('message-title-error'), t('message-error-no-document-selected'));
        } else {
            dispatch(startLoading());
            dispatch(
                sendCourseDocument({DocumentosCursos: values})
            ).then((res) => {
                dispatch(endLoading());
                if (res.ok) {
                    history.goBack();
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });    
        }
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchCourse = () => {
        const elem = document.getElementById('modal-selector-curso');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSearchDocument = () => {
        const elem = document.getElementById('modal-selector-documento');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectCourse = (course) => {
        const elem = document.getElementById('modal-selector-curso');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setCourse(course);
        dispatch(change('courseDocumentForm', 'curso_id', course.id));
    };

    const handleSelectDocument = (documento) => {
        const elem = document.getElementById('modal-selector-documento');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setDocument(documento);
        dispatch(change('courseDocumentForm', 'documento_id', documento.id));
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    <label className="ml-1">{t('screens-course-selector-curso')}</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-curso"
                            onClick={ handleSearchCourse }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ course.titulo || t('selector-course-no-selected') }</span>
                    </p>
                    <Field type="hidden" name="curso_id" id="curso_id"
                        value= { course.id }
                        component={ renderFieldOneColumn }
                    />
                </div>
                    
                <div className="row margin">
                    <label className="ml-1">{t('screens-document-list-archivo')}</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-documento"
                            onClick={ handleSearchDocument }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ documento.label || t('selector-document-no-selected') }</span>
                    </p>
                    <Field type="hidden" name="documento_id" id="documento_id"
                        value= { documento.id }
                        component={ renderFieldOneColumn }
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-course-document-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-curso" className="modal">
                <div className="modal-content">
                    <ScreensCourseSelector
                        onSelect={ handleSelectCourse }
                        fetchCourseList={ fetchCourseList }
                    />
                </div>
            </div>

            <div id="modal-selector-documento" className="modal">
                <div className="modal-content">
                    <ScreensDocumentSelector
                        onSelect={ handleSelectDocument }
                        fetchDocumentList={ fetchDocumentList }
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.curso_id) {
        errors.curso_id = i18n.t('validator-error-curso');
    }

    if (!values.documento_id) {
        errors.documento_id = i18n.t('validator-error-documento');
    }

    return errors;
};

export default reduxForm({
    form: 'courseDocumentForm',
    validate: validate,
    destroyOnUnmount: false, //true
})(ScreensDocumentCourseForm);