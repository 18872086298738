import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field, change } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import Divider from '../../components/UI/Divider';
import ScreensUserSelector from '../../screens/User/Selector';
import ScreensUserNew from '../../screens/User/New';
import DatePicker from '../../components/UI/DatePicker';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns,
    renderTextareaOneColumn,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendStudent } from '../../actions/student';
import { startLoading, endLoading, fetchDemarcacionList } from '../../actions/ui';
import { fetchStudentUserList, sendUser } from '../../actions/user';

import { showError, showConfirmation } from '../../helpers/messages';
import { dateToDatepicker, stringTrim } from '../../helpers/converter';
import { isEmail, max2digits } from '../../helpers/validators';

const ScreensStudentForm = ({ submitting, handleSubmit, dirty }) => {

    const fileInputFoto = React.createRef();

    const fileInputAdjunto = React.createRef();

    const { t } = useTranslation();

    const { user: userLogged} = useSelector(state => state.auth);

    const { selected } = useSelector(state => state.student);

    const [user, setUser] = useState((selected && selected.user) ? selected.user : { id: '', label: t('selector-user-no-selected') });

    const { demarcacionList } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    const history = useHistory();

    const [isUserCreated, setIsUserCreated] = useState(false);

    useEffect(() => {
        dispatch(fetchDemarcacionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(initialize('studentForm', {
                id: selected.id,
                nombre: selected.nombre, 
                apellidos: selected.apellidos, 
                email: selected.email, 
                demarcacion_id: selected.demarcacion_id,
                dni: selected.dni,
                trabajo: selected.trabajo,
                telefono_fijo: selected.telefono_fijo,
                telefono_movil: selected.telefono_movil,
                comienzo: selected.comienzo,
                formacion_previa_acreditada: selected.formacion_previa_acreditada,
                direccion: selected.direccion,
                poblacion: selected.poblacion,
                provincia: selected.provincia,
                pais: selected.pais,
                codigo_postal: selected.codigo_postal,
                otros_datos: selected.otros_datos,
                foto: selected.foto,
                adjunto: selected.adjunto,
                constelador_acreditado: selected.constelador_acreditado,
                es_constelador: selected.es_constelador,
                capacidad_consteladora: selected.capacidad_consteladora,
                libros_comentados: selected.libros_comentados,
                fecha_capacidad_consteladora: selected.fecha_capacidad_consteladora,
                master: selected.master,
                maestria: selected.maestria,
                especialista: selected.especialista,
                experto: selected.experto,
                fecha_master: selected.fecha_master,
                datepicker_comienzo: dateToDatepicker(selected.comienzo),
                datepicker_fecha_master: dateToDatepicker(selected.fecha_master),
                datepicker_fecha_capacidad_consteladora: dateToDatepicker(selected.fecha_capacidad_consteladora),
                acepta_politica_privacidad: (selected.user) ? selected.user.acepta_politica_privacidad : '',
                acepta_emails: (selected.user) ? selected.user.acepta_emails : ''
            }));

            if (selected.user) {
                setUser(selected.user);
                dispatch(change('studentForm', 'user_id', selected.user.id));
            }

        } else {
            dispatch(initialize('studentForm', { 
                nombre: '', 
                apellidos: '', 
                email: '', 
                demarcacion_id: '',
                dni: '',
                trabajo: '',
                telefono_fijo: '',
                telefono_movil: '',
                comienzo: '',
                formacion_previa_acreditada: '',
                direccion: '',
                poblacion: '',
                provincia: '',
                pais: '',
                codigo_postal: '',
                otros_datos: '',
                foto: '',
                adjunto: '',
                constelador_acreditado: '',
                es_constelador: '',
                capacidad_consteladora: '',
                libros_comentados: '',
                fecha_capacidad_consteladora: '',
                master: '',
                maestria: '',
                experto: '',
                especialista: '',
                fecha_master: '',
                datepicker_comienzo: '',
                datepicker_fecha_master: '',
                datepicker_fecha_capacidad_consteladora: '',
                acepta_politica_privacidad: '',
                acepta_emails: ''
            }));
        }

        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch, selected]);

    const handleSend = (values) => {

        if (!values.user_id) {
            // Comprobar si se ha generado un usuario para el alumno
            showError(t('message-title-error'), t('Se debe crear un usuario para el alumno'));
            return;
        }

        dispatch(startLoading());

        const archivoFoto = fileInputFoto.current.files[0];
        const archivoAdjunto = fileInputAdjunto.current.files[0];

        dispatch(
            sendStudent({
                ...values,
                nombre: stringTrim(values.nombre),
                apellidos: stringTrim(values.apellidos),
                email: stringTrim(values.email),
                dni: stringTrim(values.dni),
                trabajo: stringTrim(values.trabajo),
                telefono_fijo: stringTrim(values.telefono_fijo),
                telefono_movil: (values.telefono_movil),
                direccion: stringTrim(values.direccion),
                poblacion: stringTrim(values.poblacion),
                provincia: stringTrim(values.provincia),
                pais: stringTrim(values.pais),
                codigo_postal: stringTrim(values.codigo_postal),
                otros_datos: stringTrim(values.otros_datos),
                formacion_previa_acreditada: (values.formacion_previa_acreditada) ? 1 : 0,
                constelador_acreditado: (values.constelador_acreditado) ? 1 : 0,
                es_constelador: (values.es_constelador) ? 1 : 0,
                capacidad_consteladora: (values.capacidad_consteladora) ? 1 : 0,
                libros_comentados: stringTrim(values.libros_comentados),
                master: (values.master) ? 1 : 0,
                maestria: (values.maestria) ? 1 : 0,
                especialista: (values.especialista) ? 1 : 0,
                experto: (values.experto) ? 1 : 0,
                foto: archivoFoto,
                adjunto: archivoAdjunto,
                acepta_politica_privacidad: (values.acepta_politica_privacidad) ? 1 : 0,
                acepta_emails: (values.acepta_emails) ? 1 : 0
            })
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchUser = () => {
        const elem = document.getElementById('modal-selector-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectUser = (user) => {
        const elem = document.getElementById('modal-selector-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setUser(user);
        dispatch(change('studentForm', 'user_id', user.id));
    };

    const handleShowNewUser = () => {
        const emailValue = document.getElementById('email').value;
        if (emailValue && isEmail(emailValue)) {
            dispatch(change('userNewForm', 'email', emailValue));
            const elem = document.getElementById('modal-nuevo-usuario');
            const instance = M.Modal.getInstance(elem);
            instance.open();
        } else {
            const elem = document.getElementById('modal-nuevo-usuario');
            const instance = M.Modal.getInstance(elem);
            instance.close();
            showError(t('message-title-error'), t('message-email-before-new-user'));
        }
    };

    const handleNewUser = (values) => {
        const elem = document.getElementById('modal-nuevo-usuario');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        dispatch(startLoading());
        dispatch(
            sendUser({ Users: values })
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                setUser(res.message.user);
                dispatch(change('studentForm', 'user_id', res.message.user.id));
                setIsUserCreated(true);
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const isValidArea = (demarcacion_id) => {
        if (userLogged.organizadores.length > 0) {
            const demarcacionUser = userLogged.organizadores[0].demarcacion.id;
            const demarcacionParentUser = userLogged.organizadores[0].demarcacion.parent_id;
            return (demarcacionUser === demarcacion_id || demarcacionParentUser === demarcacion_id);
            // return userLogged.organizadores[0].demarcacion_id === demarcacion_id;
        } else if (userLogged.alumnos.length > 0 && userLogged.alumnos[0].  _homologados.length > 0) {
            const demarcacionUser = userLogged.alumnos[0].formadores_homologados.id;
            const demarcacionParentUser = userLogged.alumnos[0].formadores_homologados.parent_id;
            // const demarcacionUser = userLogged.alumnos[0].formadores_homologados.demarcacion.id;
            // const demarcacionParentUser = userLogged.alumnos[0].formadores_homologados.demarcacion.parent_id;
            return (demarcacionUser === demarcacion_id || demarcacionParentUser === demarcacion_id);
            // return userLogged.alumnos[0].formadores_homologados.demarcacion_id === demarcacion_id;
        } else {
            return true;
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                {/* Campos del formulario */}
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-student-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre"
                        maxLength="128"
                    />

                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-student-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos"
                        maxLength="128" 
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="email" id="email" 
                        label={ t('screens-student-list-email') } 
                        component={ renderFieldTwoColumns } 
                        icon="email" 
                        key="email"
                        maxLength="256"
                        readOnly={isUserCreated}
                    />
                    <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-student-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="location_searching" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                (isValidArea(demarcacion.id))
                                ? <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                                : <></>
                            ))
                        }
                    </Field>
                </div>

                <Divider />

                <div className="row margin">
                    <Field type="hidden" name="user_id" id="user_id"
                        value= { user.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{t('Dados de acesso')}</label>
                    <p>
                        {/*
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-usuario"
                            onClick={ handleSearchUser }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        */}
                        {

                        }
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-nuevo-usuario"
                            onClick={ handleShowNewUser }
                            disabled={ user.username }
                        >
                            <i className="material-icons">add</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ user.username || t('selector-user-no-created') }</span>
                    </p>
                </div>

                <Spacer height="3" />

                <div className="row margin">
                    <Field type="checkbox" name="acepta_politica_privacidad" id="acepta_politica_privacidad" 
                        className="filled-in"
                        label={ t('screens-student-list-acepta_politica_privacidad') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="acepta_politica_privacidad"
                    />
                    <Field type="checkbox" name="acepta_emails" id="acepta_emails" 
                        className="filled-in"
                        label={ t('screens-student-list-acepta_emails') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="acept_emails"
                    />
                </div>

                <Divider />

                <div className="row margin">
                    <Field type="text" name="dni" id="dni" 
                        label={ t('screens-student-list-dni') } 
                        component={ renderFieldTwoColumns } 
                        icon="fingerprint" 
                        key="dni" 
                        maxLength="9"
                    />
                    <Field type="text" name="trabajo" id="trabajo" 
                        label={ t('screens-student-list-trabajo') } 
                        component={ renderFieldTwoColumns } 
                        icon="build" 
                        key="trabajo" 
                        maxLength="128"
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="telefono_fijo" id="telefono_fijo" 
                        label={ t('screens-student-list-telefono_fijo') } 
                        component={ renderFieldTwoColumns } 
                        icon="phone" 
                        key="telefono_fijo" 
                        maxLength="24"
                    />
                    <Field type="text" name="telefono_movil" id="telefono_movil" 
                        label={ t('screens-student-list-telefono_movil') } 
                        component={ renderFieldTwoColumns } 
                        icon="phone_android" 
                        key="telefono_movil" 
                        maxLength="24"
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="direccion" id="direccion" 
                        label={ t('screens-student-list-direccion') } 
                        component={ renderFieldTwoColumns } 
                        icon="contact_mail" 
                        key="direccion" 
                        maxLength="256"
                    />
                    <Field type="text" name="poblacion" id="poblacion" 
                        label={ t('screens-student-list-poblacion') } 
                        component={ renderFieldTwoColumns } 
                        icon="location_city" 
                        key="poblacion" 
                        maxLength="128"
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="provincia" id="provincia" 
                        label={ t('screens-student-list-provincia') } 
                        component={ renderFieldTwoColumns } 
                        icon="map" 
                        key="provincia" 
                        maxLength="128"
                    />

                    <Field type="text" name="codigo_postal" id="codigo_postal" 
                        label={ t('screens-student-list-codigo_postal') } 
                        component={ renderFieldTwoColumns } 
                        icon="domain" 
                        key="codigo_postal" 
                        maxLength="5"
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="pais" id="pais" 
                        label={ t('screens-student-list-pais') } 
                        component={ renderFieldOneColumn } 
                        icon="map" 
                        key="pais" 
                        maxLength="128"
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="otros_datos" id="otros_datos" 
                        label={ t('screens-student-list-otros_datos') } 
                        component={ renderTextareaOneColumn } 
                        icon="assignment" 
                        key="otros_datos" 
                        maxLength="512"
                    />
                </div>

                <Divider />

                <div className="row margin">
                    <div className={`input-field file-field col s12 m6`}>
                        <div className="btn">
                            <span>{ t('screens-student-list-foto') }</span>
                            <input name="foto" type="file" key="foto" id="foto" ref={ fileInputFoto } />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                        <small className="errorTxt6">
                            <div id="crole-error" className="error"></div>
                        </small>      
                    </div>

                    <div className={`input-field file-field col s12 m6`}>
                        <div className="btn">
                            <span>{ t('screens-student-list-adjunto') }</span>
                            <input name="adjunto" type="file" key="adjunto" id="adjunto" ref={ fileInputAdjunto } />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                        <small className="errorTxt6">
                            <div id="crole-error" className="error"></div>
                        </small>      
                    </div>
                </div>

                <Divider />

                <div className="row margin">
                    <Field type="checkbox" name="formacion_previa_acreditada" id="formacion_previa_acreditada" 
                        className="filled-in"
                        label={ t('screens-student-list-formacion_previa_acreditada') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="formacion_previa_acreditada"
                    />

                    <Field type="checkbox" name="constelador_acreditado" id="constelador_acreditado" 
                        className="filled-in"
                        label={ t('screens-student-list-constelador_acreditado') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="constelador_acreditado"
                    />

                    <Field type="checkbox" name="es_constelador" id="es_constelador" 
                        className="filled-in"
                        label={ t('screens-student-list-es_constelador') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="es_constelador"
                    />

                    <Field type="checkbox" name="capacidad_consteladora" id="capacidad_consteladora" 
                        className="filled-in"
                        label={ t('screens-student-list-capacidad_consteladora') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="capacidad_consteladora"
                    />

                    <Field type="checkbox" name="master" id="master" 
                        className="filled-in"
                        label={ t('screens-student-list-master') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="master"
                    />

                    <Field type="checkbox" name="maestria" id="maestria" 
                        className="filled-in"
                        label={ t('screens-student-list-maestria') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="maestria"
                    />

                    <Field type="checkbox" name="especialista" id="especialista" 
                        className="filled-in"
                        label={ t('screens-student-list-especialista') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="especialista"
                    />

                    <Field type="checkbox" name="experto" id="experto" 
                        className="filled-in"
                        label={ t('screens-student-list-experto') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="experto"
                    />
                </div>

                <Spacer height="2" />

                <div className="row margin">
                    <DatePicker form="studentForm" name="comienzo" 
                        value={(selected) ? selected.comienzo : ''}
                        label={ t('screens-student-list-comienzo') }
                        render={ renderFieldTwoColumns }
                    />
                    <DatePicker form="studentForm" name="fecha_capacidad_consteladora" 
                        value={(selected) ? selected.fecha_capacidad_consteladora : ''}
                        label={ t('screens-student-list-fecha_capacidad_consteladora') }
                        render={ renderFieldTwoColumns }
                    />
                    <DatePicker form="studentForm" name="fecha_master" 
                        value={(selected) ? selected.fecha_master : ''}
                        label={ t('screens-student-list-fecha_master') }
                        render={ renderFieldTwoColumns }
                    />
                    <Field type="text" name="libros_comentados" id="libros_comentados" 
                        label={ 'Libros Comentados' } 
                        component={ renderFieldTwoColumns } 
                        icon="bookshelf" 
                        key="libros_comentados"
                        maxLength="2"
                        normalize={ max2digits }
                    />
                </div>

                <Divider />

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>    
            <PageTitle title={ t('screens-student-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-usuario" className="modal">
                <div className="modal-content">
                    <ScreensUserSelector
                        onSelect={ handleSelectUser }
                        fetchUserList={ fetchStudentUserList }
                    />
                </div>
            </div>

            <div id="modal-nuevo-usuario" className="modal">
                <div className="modal-content">
                    <ScreensUserNew
                        onSelect={ handleNewUser }
                        role="ROLE_ALUMNO"
                        hideEmail
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = i18n.t('validator-error-nombre');
    }

    if (!values.apellidos) {
        errors.apellidos = i18n.t('validator-error-apellidos');
    }

    if (!values.email) {
        errors.email = i18n.t('validator-error-email');
    }

    if (!values.demarcacion_id) {
        errors.demarcacion_id = i18n.t('validator-error-demarcacion');
    }

    return errors;
};

export default reduxForm({
    form: 'studentForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensStudentForm);