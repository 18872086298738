import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import DatePicker from '../../components/UI/DatePicker';

import { 
    renderFieldTwoColumns,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

import { fetchStudentTitulaciones, sendStudentDegrees } from '../../actions/student';
import { startLoading, endLoading } from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { dateToDatepicker } from '../../helpers/converter';

const ScreensStudentDegrees = ({ submitting, handleSubmit, dirty }) => {

    const { t } = useTranslation();

    const { selected, degrees } = useSelector(state => state.student);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        if (selected) {
            dispatch(startLoading());
            dispatch(
                fetchStudentTitulaciones(selected.id, {})
            ).then((res) => {
                dispatch(endLoading());
                if (!res.ok) {
                    showError(t('message-title-error'), t(res.message));
                }
            });
        }
    }, [dispatch, selected]);

    useEffect(() => {
        if (selected) {
            const formValues = {
                constelador_acreditado: selected.constelador_acreditado,
            };
            degrees.diplomas_tipos.forEach(tipo => {
                formValues[`check_${tipo.id.split('-').join('_')}`] = false;
                formValues[`fecha_${tipo.id.split('-').join('_')}`] = '';
                formValues[`datepicker_fecha_${tipo.id.split('-').join('_')}`] = '';
            });
            degrees.titulaciones.forEach(titulacion => {
                formValues[`check_${titulacion.diplomas_tipo_id.split('-').join('_')}`] = true;
                formValues[`fecha_${titulacion.diplomas_tipo_id.split('-').join('_')}`] = titulacion.fecha_1;
                formValues[`datepicker_fecha_${titulacion.diplomas_tipo_id.split('-').join('_')}`] = dateToDatepicker(titulacion.fecha_1);
            });
            dispatch(initialize('studentDegreesForm', formValues));
        }
    },[dispatch, selected, degrees]);

    const handleSend = (values) => {
        dispatch(startLoading());
        dispatch(
            sendStudentDegrees(selected.id, {Alumnos: {
                ...values,
                constelador_acreditado: (values.constelador_acreditado) ? 1 : 0,
            }})
        ).then((res) => {
            dispatch(endLoading());
            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const getFecha = (diplomas_tipo_id) => {
        return degrees.titulaciones.reduce((acc, titulacion) => {
            if (titulacion.diplomas_tipo_id === diplomas_tipo_id && titulacion.fecha_1) {
                return dateToDatepicker(titulacion.fecha_1);
            } else {
                return acc;
            }
        }, '-');
    };

    const getChecked = (diplomas_tipo_id) => {
        return degrees.titulaciones.reduce((acc, titulacion) => {
            return acc || (titulacion.diplomas_tipo_id === diplomas_tipo_id);
        }, false);
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                
                {
                    degrees.diplomas_tipos.map(tipo => {
                        return (<div className="row margin" style={{borderBottom: '1px solid #CDCDCD', paddingTop: '5px'}} key={`block_${tipo.id}`}>
                            <Can perform="Alumnos:titulaciones:enviar"
                                yes={() => (
                                    <Field type="checkbox" name={`check_${tipo.id.split('-').join('_')}`} id={`check_${tipo.id.split('-').join('_')}`} 
                                        className="filled-in"
                                        label={ tipo.nombre } 
                                        component={ renderCheckFieldTwoColumns } 
                                        icon="check"
                                        key={tipo.id}
                                    />
                                )}
                                no={() => {
                                    if (getChecked(tipo.id)) {
                                        return (<div className="col s12 m6">
                                            <label>{ tipo.nombre }</label>
                                            <p>
                                                <i className="material-icons orange-text text-darken-2">check</i>
                                            </p>
                                        </div>);
                                    } else {
                                        return (<div className="col s12 m6">
                                            <label>{ tipo.nombre }</label>
                                            <p>-</p>
                                        </div>);
                                    }
                                }}
                            />
            
                            <Can perform="Alumnos:titulaciones:enviar"
                                yes={() => (
                                    <DatePicker form="studentDegreesForm" name={`fecha_${tipo.id.split('-').join('_')}`} 
                                        value=""
                                        label={ t('screens-diploma-list-fecha') }
                                        render={ renderFieldTwoColumns }
                                        editable={false}
                                    />
                                )}
                                no={() => (
                                    <div className="col s12 m6">
                                        <label>{ t('screens-diploma-list-fecha') }</label>
                                        <p>{ getFecha(tipo.id) }</p>
                                    </div>
                                )}
                            />
                            <Spacer height="1" />
                        </div>);
                    })
                }

                <Spacer height="2" />

                <div className="row margin">
                    <label>{ t('Titulaciones Online') }</label><br />
                    <Spacer height="1" />
                    <Can perform="Alumnos:titulaciones:enviar"
                        yes={() => (
                            <Field type="checkbox" name="constelador_acreditado" id="constelador_acreditado" 
                                className="filled-in"
                                label={ t('screens-student-list-constelador_acreditado') } 
                                component={ renderCheckFieldTwoColumns } 
                                icon="check"
                                key="constelador_acreditado"
                            />
                        )}
                        no={() => {
                            if (selected && selected.constelador_acreditado) {
                                return (<div className="col s12 m6">
                                    <label>{ t('screens-student-list-constelador_acreditado') }</label>
                                    <p>
                                        <i className="material-icons orange-text text-darken-2">check</i>
                                    </p>
                                </div>);
                            } else {
                                return (<div className="col s12 m6">
                                    <label>{ t('screens-student-list-constelador_acreditado') }</label>
                                    <p>-</p>
                                </div>);
                            }
                        }}
                    />
                </div>

                <Spacer height="2" />

                <div className="row">
                    <div className="input-field col s12">
                        {
                            <Can perform="Alumnos:titulaciones:enviar" yes={() => (
                                <FormButton type="submit"
                                    title={ t('form-button-send') }
                                    disabled={ submitting }
                                />
                            )} />
                        }

                        <FormButton type="reset"
                            title={ t('screens-list-acciones-volver') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>    
            <PageTitle title={ t('screens-student-degrees-title') } />

            <blockquote className="small red-text">
                <label>{ selected ? `${selected.nombre} ${selected.apellidos}` : '' }</label>
            </blockquote>

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    return errors;
};

export default reduxForm({
    form: 'studentDegreesForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensStudentDegrees);