import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns 
} from '../../../components/UI/Fileds';

import { sendBookSection } from '../../../actions/bookSection';
import { startLoading, endLoading } from '../../../actions/ui';

import { max4digits } from '../../../helpers/validators';
import { showError, showConfirmation } from '../../../helpers/messages';
import { stringTrim } from '../../../helpers/converter';

const ScreensBookSectionForm = ({ submitting, handleSubmit, reset, dirty }) => {
    
    const { t } = useTranslation();

    const { selected } = useSelector(state => state.bookSection);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        if (selected) {
            dispatch(initialize('bookSectionForm', {
                id: selected.id,
                nombre: selected.nombre,
                codigo: selected.codigo
            }));
        } else {
            dispatch(initialize('bookSectionForm', { nombre: '', codigo: '' }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {
        dispatch(startLoading());

        dispatch(
            sendBookSection({BibliotecaSecciones: {
                ...values,
                nombre: stringTrim(values.nombre)
            }})
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                {/* Campos del formulario */}
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="number" name="codigo" id="codigo" 
                        label={ t('screens-book-section-list-codigo') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="label_outline" 
                        key="codigo"
                        normalize={ max4digits }
                    />

                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-book-section-list-nombre') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="nombre"
                    />
                </div>

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-book-section-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.codigo) {
        errors.codigo = i18n.t('validator-error-codigo');
    }

    if (!values.nombre) {
        errors.nombre = i18n.t('validator-error-nombre');
    }

    return errors;
};

export default reduxForm({
    form: 'bookSectionForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensBookSectionForm);