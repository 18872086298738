export const i18nDatepickerPt = {
    cancel:	'Cancelar',
    clear: 'Excluir',
    done: 'Ok',
    months:[
        'Janeiro', 
        'Fevereiro', 
        'Março', 
        'Abril', 
        'Posso', 
        'Junho', 
        'Julho', 
        'Agosto', 
        'Setembro', 
        'Outubro', 
        'Novembro', 
        'Dezembro'
    ],
    monthsShort: [
        'Jan', 
        'Fev', 
        'Mar', 
        'Abr', 
        'Pos', 
        'Jun', 
        'Jul', 
        'Ago', 
        'Set', 
        'Out', 
        'Nov', 
        'Dez'
    ],
    weekdays: [
        'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta feira', 'Quinta feira', 'Sexta-feira', 'Sábado'
    ],
    weekdaysShort: [
        'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'
    ],
    weekdaysAbbrev: ['D','S','T','Q','Q','S','S']
};